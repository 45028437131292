import React, { useContext, useEffect, useState } from "react";
import {observer} from "mobx-react-lite";
import Header from "../RosterView/Header";
import styles from "./FullTableView.module.css"
import TransportLayer, {TeamScoreDTO} from "../../../../../../state/TransportLayer";
import { stringify } from "querystring";
import { RootContext } from "../../../../../../App";

const RosterView = observer(() => {
  const rootContext = useContext(RootContext);

  const table = rootContext.matchStore.calculateScoreTable;

  const items = table?.sort((a,b) => { return a.points > b.points ? -1 : 1 }).map((item, idx) => {
    const teamName = rootContext.teamStore.getTeamName(item.team_id)
    return (
      <tr key={"fulltable_tr_"+idx} className={styles.tr}>
        <td className={styles.td}>{idx+1}.</td>
        <td className={styles.td}>{teamName}</td>
        <td className={styles.td}>{item.matches}</td>
        <td className={styles.td}>{item.won}</td>
        <td className={styles.td}>{item.draws}</td>
        <td className={styles.td}>{item.lost}</td>
        <td className={`${styles.td} ${styles.tdGoals}`}>{item.goals_given}:{item.goals_taken}</td>
        <td className={styles.td}>{item.points}</td>
      </tr>
    )
  })

  return (
    <React.Fragment>
      <table className={styles.table}>
        <tbody>
          <tr className={styles.tr}>
            <th className={styles.th}>#</th>
            <th className={styles.th}>TÝM</th>
            <th className={styles.th}>ZÁPASY</th>
            <th className={styles.th}>VÝHRY</th>
            <th className={styles.th}>REMÍZY</th>
            <th className={styles.th}>PROHRY</th>
            <th className={styles.th}>SKÓRE</th>
            <th className={styles.th}>BODY</th>
          </tr>
          { items }
        </tbody>
      </table>
    </React.Fragment>
  )
});

export default RosterView;

import { action, computed, makeAutoObservable, makeObservable, observable } from "mobx";
import RootStore from "./RootStore"
import TransportLayer, { PlayerDTO } from "./TransportLayer"

export interface TeamInfoDTO {
  id: number;
  name: string;
  rowState: string;
}

export class TeamStore {
    rootStore;
    transportLayer;
    teams: TeamInfoDTO[] = [];

    constructor(rootStore: RootStore, transportLayer: TransportLayer) {
      makeAutoObservable(this)
      this.transportLayer = transportLayer
      this.rootStore = rootStore
    }

    setTeams(teams: TeamInfoDTO[]) {
      this.teams = teams;
    }

    getTeamName(id: number): string {
      return this.teams.find(it => it.id == id)?.name || 'NAME NOT FOUND'
    }

    getTeam(id: number): TeamInfoDTO | undefined {
      return this.teams.find(it => it.id == id) || undefined
    }
}

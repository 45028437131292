import dayjs from "dayjs";
import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
import { RootContext } from "../../../../../App";
import { MatchObject } from "../../../../../state/MatchStore";
import { rootStore } from "../../../../../state/RootStore";
import { TeamInfoDTO } from "../../../../../state/TeamStore";
import styles from "./TeamForm.module.css"


enum Status {
  Won, Lost, Par, None
}

export interface TeamFormProps {
  teamName: string;
}

const TeamForm = observer((props: TeamFormProps) => {
  const rootContext = useContext(RootContext)

  let results: Status[] = []
  const teamDTO = rootContext.teamStore.teams.find(it => it.name == props.teamName)

  if(teamDTO)
    results = GetTeamMatchForm(rootContext.matchStore.matches, rootContext.teamStore.teams.find(it => it.name == props.teamName)!!);

    
    const items = results.map((it, idx) => {
      let style = ''
      let text = ''
      let title = ''
      switch (it) {
        case Status.Lost:
          style = styles.red
          text = 'P'
          title = 'Prohra'
          break;
        case Status.Won:
          style = styles.green
          text = 'V'
          title = 'Výhra'
          break;
        case Status.Par:
          style = styles.yellow
          text = 'R'
          title = 'Remíza'
          break;
        default:
          break;
      }
      return (
      <React.Fragment key={idx}>
        <span title={title} className={style}>{text}</span>
      </React.Fragment>
    )
  })

  return (
    <React.Fragment>
      {items}
    </React.Fragment>
  )
})

export default TeamForm;

function GetTeamMatchForm(matches: MatchObject[], team: TeamInfoDTO): Status[] {
  const teamWins: Status[] = []
  const slices = matches
    .filter(it => it.team1Id == team.id || it.team2Id == team.id)
    .filter(it => it.played)
    .sort((a,b) => dayjs(a.time).tz().isBefore(dayjs(b.time).tz()) ? -1 : 1)
    .slice(-5)
  slices
    .forEach(it => {
      let team1_status = Status.None;
      let team2_status = Status.None;
      if(it.score1 > it.score2) {
        team1_status = Status.Won;
        team2_status = Status.Lost;
      } else if(it.score1 < it.score2) {
        team1_status = Status.Lost;
        team2_status = Status.Won;
      } else {
        team1_status = team2_status = Status.Par;
      }
      
      if(it.team1Id == team.id)
        teamWins.push(team1_status);
      else if (it.team2Id == team.id) 
        teamWins.push(team2_status);
    }
  );

  return teamWins;
}

import { makeAutoObservable } from "mobx";
import { observer } from "mobx-react-lite";
import { createContext, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { RootContext } from "../../../../App";
import { MatchObject } from "../../../../state/MatchStore";
import czSort from "../../../../utils/czSort";
import { BackofficeContext, Header, IconBack, IconSave } from "../../BackofficePage";
import styles from "./MatchEditView.module.css"

class MatchEditState {
  constructor() {
    makeAutoObservable(this);
  }
}

const MatchEditView = observer(() => {
  const backofficeCtx = useContext(BackofficeContext)
  const { matchId } = useParams()
  const navigate = useNavigate()

  const context = useContext(RootContext);
  const matchStore = context.matchStore
  const teamStore = context.teamStore;
  const playerStore = context.playerStore;

  const match = matchStore.getMatchById(matchId || "")
  const [editedTeamId, setEditedTeamId] = useState(match?.team1Id)
  const [scoreTeam1, setScoreTeam1] = useState(match?.score1 || -1)
  const [scoreTeam2, setScoreTeam2] = useState(match?.score2 || 0)
  const [scorersTeam1, setScorersTeam1] = useState(match?.team1Scorers || [])
  const [scorersTeam2, setScorersTeam2] = useState(match?.team2Scorers || [])
  const [matchPlayed, setMatchPlayed] = useState(match?.played || false)

  useEffect(() => {
    backofficeCtx.hideBottomMenu = true
    return () => {
      backofficeCtx.hideBottomMenu = false
    }
  }, [])

  useEffect(() => {
    if(match === undefined) return;
    setScoreTeam1(scorersTeam1!!.length)
    setScoreTeam2(scorersTeam2!!.length)
  }, [scorersTeam1, scorersTeam2])

  useEffect(() => {
    if(match === undefined || editedTeamId !== undefined) return;
    setEditedTeamId(match!!.team1Id)
    setScoreTeam1(match!!.score1)
    setScoreTeam2(match!!.score2)
    setScorersTeam1(match!!.team1Scorers)
    setScorersTeam2(match!!.team2Scorers)
    setMatchPlayed(match!!.played)
  }, [match])

  useEffect(() => {
    if(scoreTeam1 > 0 || scoreTeam2 > 0) {
      setMatchPlayed(true)
    }
  }, [scoreTeam1, scoreTeam2])

  if(match === undefined) return (<div>Loading</div>)
  
  const team1_id = match!!.team1Id
  const team2_id = match!!.team2Id

  const team1 = teamStore.getTeamName(team1_id)
  const team2 = teamStore.getTeamName(team2_id)

  const team1Active = editedTeamId == team1_id;
  const team2Active = editedTeamId == team2_id;

  const getGoalsForPlayer = (playerId: number, scorers: number[]): number => {
    let goals = 0
    for(let i = 0; i < scorers.length; i++) {
      if(scorers[i] == playerId)
        goals++
    }
    return goals
  }

  const setGoalsForPlayer = (playerId: number, goals: number) => {
      const scorers = team1Active ? scorersTeam1.slice() : scorersTeam2.slice();

      while(true) {
        let idx = scorers.indexOf(playerId)
        if(idx === -1) break;
        scorers.splice(idx, 1)
      }

      for(let i = 0; i < goals; i++) {
        scorers.push(playerId)
      }

      team1Active ? setScorersTeam1(scorers) : setScorersTeam2(scorers)
  }

  const editedShooterOpts = playerStore.players
  .filter(p => p.teamId == editedTeamId)
  .sort((a, b) => czSort(a.name, b.name))
    .map(p => {
      return (<TeamPlayer key={p.id} playerId={p.id} playerName={p.name} goals={getGoalsForPlayer(p.id, team1Active ? scorersTeam1 : scorersTeam2 )} onGoalsChanged={setGoalsForPlayer} />)
    })

  const goBack = () => {
    navigate("..");
  }

  const save = () => {
    // const m = Object.create(match!!) as MatchObject
    const m: MatchObject = new MatchObject(
      match.uid,
      match.round,
      match.time,
      match.field,
      scoreTeam1,
      scoreTeam2,
      match.team1Id,
      match.team2Id,
      scorersTeam1,
      scorersTeam2,
      match.referee,
      matchPlayed,
      match.contumacy,
      match.postponed,
      match.year
    )
    
    matchStore.updateMatch(m)
  }

  const playedCheckboxDisabled = scoreTeam1 > 0 || scoreTeam2 > 0

  const onPlayedClicked = () => {
    if(playedCheckboxDisabled) return;

    setMatchPlayed(!matchPlayed)
  }

  return (
    <div className={styles.main}>
      <Header title="Edit" leftChild={<IconBack />} rightChild={<IconSave />} onLeftChildClick={goBack} onRightChildClick={save} />
      <div className={styles.scoreTable}>
        <div onClick={() => { setEditedTeamId(team1_id) }} className={`${styles.score} ${team1Active ? styles.active : null}`}>
          <div>{team1}</div>
          <div className={styles.goals}>{scoreTeam1}</div>
        </div>
        <div onClick={() => { setEditedTeamId(team2_id) }} className={`${styles.score} ${team2Active ? styles.active : null}`}>
          <div>{team2}</div>
          <div className={styles.goals}>{scoreTeam2}</div>
        </div>
        <div className={styles.played} onClick={onPlayedClicked}>
          Odehráno
          <div className={styles.playedCheckbox}>
            <input checked={matchPlayed} disabled={playedCheckboxDisabled} className={styles.playedCheckbox} type="checkbox" />
          </div>
        </div>
      </div>
      <div className={styles.players}>
        <div>
        {editedShooterOpts}
        </div>
      </div>
    </div>
  )
})

export default MatchEditView

interface TeamPlayerProps {
  playerId: number;
  playerName: string;
  goals: number;
  onGoalsChanged(playerId: number, newGoals: number): void;
}

const TeamPlayer = (props: TeamPlayerProps) => {
  const plus = () => {
    props.onGoalsChanged(props.playerId, props.goals + 1)
  }

  const minus = () => {
    const newGoals = Math.max(0, props.goals - 1)
    props.onGoalsChanged(props.playerId, newGoals)
  }

  return (
    <div className={styles.player}>
      {props.playerName}
      <div className={styles.goalsInput}>
        <div className={styles.goalsButton} onClick={minus}><span className={styles.higher}>-</span></div>
        <div className={styles.playerGoals}>{props.goals}</div>
        <div className={styles.goalsButton} onClick={plus}>+</div>
      </div>
    </div>
  )
}

import styles from "./MatchesTable.module.css"
import { MatchObject, MatchStore } from "../../../../state/MatchStore";
import { Observer } from "mobx-react-lite"
import dayjs from "dayjs";
import { TeamStore } from "../../../../state/TeamStore";
import AddMatchBox from "./AddMatchBox";
import { Header } from "../../BackofficePage";
import { PowerInputSharp } from "@mui/icons-material";

interface MatchesTableProps {
  matchStore: MatchStore;
  teamStore: TeamStore;
  activeMatchId?: string;
  onMatchClicked(matchId: string): void;
  onMatchCreateClicked(): void;
}

export default function MatchesTable(props: MatchesTableProps) {
  return (
    <Observer>
      {() => {
        return (
          <div className={styles.matchesTableContainer}>
            <Header title="Zapasy" />
            <div className={styles.tableMain}>
              <div className={`${styles.tableHeader} ${styles.header}`}>
                <div>Datum</div>
                <div>Zapas</div>
              </div>
              <div className={styles.table}>
                {props.matchStore.matches.slice().sort((a,b)=>{ return dayjs(a.time).tz() > dayjs(b.time).tz() ? 1 : -1 }).reverse().map(match => (
                  <MatchTableItem match={match} onMatchClicked={props.onMatchClicked} teamStore={props.teamStore} key={match.uid} />
                ))}
                <div className={styles.tableItem} onClick={props.onMatchCreateClicked}>
                  <div className={styles.newMatchItem}>
                    Vytvořit nový zápas
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      }}
    </Observer>
  )
}

interface MatchTableItemsProps {
  match: MatchObject
  onMatchClicked(uid: string): void
  teamStore: TeamStore
}

const MatchTableItem = (props: MatchTableItemsProps) => {
  return (
    <div key={props.match.uid}
          className={`${styles.tableItem} ${props.match.round % 2 === 0 ? styles.even : ''}`}
          onClick={() => {props.onMatchClicked(props.match.uid)}}>
      <div className={styles.view}>
        <div className={styles.playedCheck}>{props.match.played && "✅"}</div>
        <div>
          { props.match.postponed === false && dayjs(props.match.time).tz().format("DD.MM.YY") }
          { props.match.postponed && "Odloženo" }
        </div>
        <div className={styles.matchGrid}>
          <div className={styles.nameScoreGrid}>
            <div>{props.teamStore.getTeamName(props.match.team1Id)}</div>
            <div>{props.match.score1}</div>
          </div>
          <div className={styles.nameScoreGrid}>
            <div>{props.teamStore.getTeamName(props.match.team2Id)}</div>
            <div>{props.match.score2}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

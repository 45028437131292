import { ReactNode } from "react";
import Item, { TableItemProps } from "./TableItem";
import styles from './TableList.module.css';

export interface TableListProps {
  children?: ReactNode;
}

export function TableList(props: TableListProps) {
  return (
    <div className={styles.table}>
      {props.children}
    </div>
  )
}

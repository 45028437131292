import {  Navigate, NavLink, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Login from "./Login";
import { createContext, useContext, useEffect, useRef, useState } from "react";
import styles from "./BackofficePage.module.css"
import MatchesPage from "./Admin/MatchesPage/MatchesPage";
import { observer } from "mobx-react-lite";
import useRequireLogin from "./Hooks/UseRequireLogin";
import { makeAutoObservable } from "mobx";
import TeamsPage from "./Admin/TeamsPage/TeamsPage";
import saveIcon from '../../images/save-icon.svg'

const Backoffice = observer(() => {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="*" element={<Main />} />
    </Routes>
  )
})

class BackofficeState {
  private _headerText: string = ""
  private _hideBottomMenu: boolean = false

  constructor() {
    makeAutoObservable(this)
  }

  set setHeaderText(headerText: string) {
    this._headerText = headerText
  }

  get headerText(): string {
    return this._headerText
  }

  get hideBottomMenu() {
    return this._hideBottomMenu
  }

  set hideBottomMenu(value: boolean) {
    this._hideBottomMenu = value
  }
}

export const BackofficeContext = createContext<BackofficeState>(new BackofficeState())

const Main = observer(() => {
  const [_, Logout] = useRequireLogin();
  const backofficeCtx = useContext(BackofficeContext)
  const location = useLocation()

  return (
    <BackofficeContext.Provider value={backofficeCtx}>
      <div className={`${styles.main} ${backofficeCtx.hideBottomMenu ? styles.bottomMenuHidden : null}`}>
          <div className={styles.content}>
            <Routes>
              <Route path="/" element={<Navigate to="zapasy" replace />} />
              <Route path="/zapasy/*" element={<MatchesPage />} />
              <Route path="/tymy/*" element={<TeamsPage />} />
            </Routes>
          </div>

          <div className={`${styles.nav} ${backofficeCtx.hideBottomMenu ? styles.hidden : null}`}>
            <div className={styles.navItems}>
              <NavLink className={styles.navItem} to={"zapasy"}>
                <span className={`${styles.dot} ${location.pathname.includes("/admin/zapasy") && styles.active}`}></span>
                Zapasy
              </NavLink>
              <NavLink className={styles.navItem} to={"tymy"}>
                <span className={`${styles.dot} ${location.pathname.includes("/admin/tymy") && styles.active}`}></span>
                Tymy
              </NavLink>
              <NavLink className={styles.navItem} to={"/"}>
                <span className={`${styles.dot}`}></span>
                Web
              </NavLink>
              <NavLink className={styles.navItem} onClick={Logout} to={"/admin"}>
                <span className={`${styles.dot}`}></span>
                Odhlasit
              </NavLink>
            </div>
          </div>
      </div>
    </BackofficeContext.Provider>
  )
})

export default Backoffice


export interface HeaderProps {
  leftChild?: JSX.Element
  rightChild?: JSX.Element
  title: string
  onLeftChildClick?(): void
  onRightChildClick?(): void
}

export const Header = (props: HeaderProps) => {
  return (
    <div className={styles.header}>
      <div className={styles.headerGrid}>
        <div className={styles.leftChild} onClick={props.onLeftChildClick} >
          {props.leftChild}
        </div>
        <span>{props.title}</span>
        <div className={styles.rightChild} onClick={props.onRightChildClick} >
          {props.rightChild}
        </div>
      </div>
    </div>
  )
}

interface IconProps {
  color: string;
}

const defaultIconProps: IconProps = {
  color: "black"
}

export const IconHamburger = (props: IconProps) => {
  return (
    <div>
      <svg fill={props.color} viewBox="0 0 100 65" width="30" height="30">
        <rect width="100" height="10"></rect>
        <rect y="30" width="100" height="10"></rect>
        <rect y="60" width="100" height="10"></rect>
      </svg>
    </div>
  )
}
IconHamburger.defaultProps = defaultIconProps



export const IconBack = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 25 25" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <path d="M9 13l-4 -4l4 -4m-4 4h11a4 4 0 0 1 0 8h-1" />
    </svg>
  )
}

export const IconSave = () => {
  return (
    <img src={saveIcon} width="32" style={{ paddingTop: "8px" }} />
  )
}

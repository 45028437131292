import React, { ReactNode } from 'react'
import { useNavigate } from 'react-router-dom';
import styles from './TableWithButton.module.css'
import Item, { TableItemProps } from './TableItem'
import { TableList } from './TableList';

export interface TableWithButtonsProps {
  btnDest: string;
  btnText: string;
  children?: ReactNode;
}

export default function TableWithButton(props: TableWithButtonsProps) {
  const navigate = useNavigate();
  const goToFullTable = () => {
    navigate(props.btnDest)
  }

  return (
    <React.Fragment>
      <TableList children={props.children} />
      <div className={styles.footer}>
        <button className={styles.button} onClick={goToFullTable}>{props.btnText}<span></span></button>
      </div>
    </React.Fragment>
  )
}



import { Logout } from "@mui/icons-material";
import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { RootContext } from "../../../App";

const useRequireLogin = () => {
  const context = useContext(RootContext);
  const logged = context.authStore.isAuthenticated;
  const navigate = useNavigate();

  const Logout = () => {
    context.authStore.logout();
  }

  useEffect(() => {
    if (context.authStore.isInitialized && !context.authStore.isAuthenticated) {
      navigate("/admin/login")
    }
  }, [context.authStore.isInitialized, context.authStore.isAuthenticated])

  return [logged, Logout] as const;
}

export default useRequireLogin;

import { action, computed, makeAutoObservable, makeObservable, observable } from "mobx";
import RootStore from "./RootStore"
import TransportLayer, { PlayerDTO } from "./TransportLayer"

export class PlayerStore {
    rootStore;
    transportLayer;
    players: PlayerDTO[] = [];

    constructor(rootStore: RootStore, transportLayer: TransportLayer) {
      makeAutoObservable(this)
      this.transportLayer = transportLayer
      this.rootStore = rootStore
    }

    setPlayers(players: PlayerDTO[]) {
      this.players = players;
    }

    updatePlayer(player: PlayerDTO) {
      this.players.push(player);
      this.transportLayer.updatePlayer(player);
    }

    get getPlayers() {
      return this.players.slice()
    }

    get calculateTotalPlayers(): number {
      const invalidTeams = this.rootStore.teamStore.teams.filter(team => team.rowState === "INVALID").map(t => t.id)
      return this.players.filter(p => !invalidTeams.includes(p.teamId) ).length;
    }

    getPlayerById(playerId: number) {
      return this.players.find(it => it.id === playerId)
    }

    getPlayersInTeam(teamId: number) {
      return this.players.filter(it => { 
        return it.teamId == teamId  && it.name !== "Hráč nezapsán"
      })
    }

    getNewId() {
      let maxId = 0;
      this.players.forEach(p => {
        if(p.id > maxId)
          maxId = p.id
      })

      return maxId+1;
    }
}

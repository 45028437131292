import React from 'react';
import styles from './Header.module.css'

export enum HeaderVariant {
  Left, Right, Full
}

interface HeaderProps {
  variant: HeaderVariant;
  text1: string;
  text2?: string;
  children?: JSX.Element;
}

export default function Header(props: HeaderProps) {
  return (
    <React.Fragment>
      { props.variant == HeaderVariant.Left &&
        <HeaderLeft {...props} /> || null }
      { props.variant == HeaderVariant.Right &&
          <HeaderRight {...props} /> || null }
      { props.variant == HeaderVariant.Full &&
          <HeaderFull {...props} /> || null }
    </React.Fragment>
  )
}

function HeaderLeft(props: HeaderProps) {
  return (
    <div className={styles.header}>
      <div className={styles.headerLeftA}>
        {props.text1}
      </div>
      <div className={styles.headerLeftB}>
        { props.children != null && props.children }
        { props.children == null && props.text2 }
      </div>
    </div>
  )
}

function HeaderRight(props: HeaderProps) {
  return (
    <div className={styles.header}>
      <div className={styles.headerRightA}>
        {props.text1}
      </div>
      <div className={styles.headerRightB}>
        { props.children != null && props.children }
        { props.children == null && props.text2 }
      </div>
    </div>
  )
}

function HeaderFull(props: HeaderProps) {
  return (
    <div className={styles.header}>
      <div className={styles.headerFull}>
        {props.text1}
      </div>
    </div>
  )
}


import styles from "./CustomCheckbox.module.css"

interface CustomCheckboxProps {
  checked: boolean
  onChange(): void
  label: string
}

const CustomCheckbox = (props: CustomCheckboxProps) => {
  return(
    <label className={styles.container}>{props.label}
      <input type="checkbox" checked={props.checked} onChange={ () => props.onChange() } />
      <span className={styles.checkmark}></span>
    </label>
  )
}

export default CustomCheckbox
import { observer } from 'mobx-react-lite'
import styles from './TeamsPage.module.css'
import { RootContext } from "../../../../App";
import { useContext } from 'react';
import { PlayerDTO } from '../../../../state/TransportLayer';
import czSort from '../../../../utils/czSort';

const TeamsPage = observer(() => {
  const rootContext = useContext(RootContext);
  const playerStore = rootContext.playerStore;

  return (
    <div className={styles.page}>
      <div className={styles.header}>
        Týmy
      </div>
      <div className={styles.teams_container}>
        {rootContext.teamStore.teams.filter(it => it.rowState != "INVALID")
        .map((it,idx) => {
          return <TeamItem key={"team_item_"+idx} name={it.name} members={playerStore.getPlayersInTeam(it.id)} />
        })}
      </div>
    </div>
  )
})

interface TeamItemProps {
  name: string;
  members: PlayerDTO[]
}

function TeamItem(props: TeamItemProps) {
  const lookup = new Map<string, string>()
  lookup.set("FC Ulic BOYS", "ulic.png")
  lookup.set("Ukrutní Chachaři", "chachari.svg")
  lookup.set("KFC Slavoj", "kfc.png")
  lookup.set("Partia", "partia.png")
  lookup.set("UC Onkli", "onkli.png")

  const logoSrc = lookup.get(props.name)!!

  return (
    <div className={styles.team}>
      {/* <div className={styles.team_header}>  
        <img src={`${logoSrc}`} width="120px"/>
      </div> */}
      <div className={styles.team_name}>
        {props.name}
        <div className={styles.memberCount}>
          [{props.members.length}]
        </div> 
      </div>
      <div className={styles.teamMembers}>
        {props.members.map((it) => {
          return it.name
        }).sort((a,b) => czSort(a,b)).map((it,idx) => {
          return (
            <div key={it+idx} className={styles.member}>
              <div className={styles.memberName}>{idx+1}. {it}</div>
            </div>
          )
        })}
      </div>
  </div>
  )
}

export default TeamsPage

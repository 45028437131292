import { observer } from "mobx-react-lite";
import { useState } from "react";
import { NavLink, Route, Routes } from "react-router-dom";
import NavItem, { NavItemProps } from "../../Components/NavItem";
import { rootStore, yearStore } from "../../state/RootStore";
import styles from "./Frontend.module.css"
import AboutPage from "./Pages/AboutPage/AboutPage";
import DiscussPage from "./Pages/DiscussPage/DiscussPage";
import WelcomeView from "./Pages/MainPage/Content/WelcomeView/WelcomeView";
import MainPage from "./Pages/MainPage/MainPage";
import RulesPage from "./Pages/RulesPage/RulesPage";
import TeamsPage from "./Pages/TeamsPage/TeamsPage";
import YearSelect from "./Pages/YearSelect/YearSelect";

const Frontend = observer(() => {

  const leagueSubitems: NavItemProps[] = [
    { text: "ROZPIS", href:"/liga/rozpis"},
    { text: "TÝMY", href:"/liga/tymy"},
    { text: "PRAVIDLA", href:"/liga/pravidla"},
  ]

  const yearSubitems: NavItemProps[] = yearStore.years.map(year => {
    return { text: `${year}/${year+1}`, href: "/", onClick: () => { yearStore.setYear(year); }}
  })

  const footerYear = new Date().getFullYear();
  
  return (
    <>
      <div className={styles.frontend}>
        <div className={styles.topnav}>
          <NavItem classNames={[styles.yearSelectNavItem]} href='undefined' subItems={yearSubitems} text={`${yearStore.year}/${yearStore.year+1}`}/>
          <div className={styles.topnavItems}>
            {/* <NavItem  href="/ukonceni" text="UKONČENÍ SEZÓNY" /> */}
            <NavItem  href="/zapasy" text="ZÁPASY" />
            <NavItem  href="/tabulka" text="TABULKA" />
            <NavItem  href="/strelci" text="STŘELCI" />
            <NavItem  href="/liga/rozpis" text="ROZPIS" />
            <NavItem  href="/liga/tymy" text="TÝMY" />
            <NavItem  href="/liga/pravidla" text="PRAVIDLA" />
            {/* <NavItem  href="undefined" text="LIGA" subItems={leagueSubitems} /> */}
            {/* <NavItem  href="/admin" text="ADMIN" /> */}
            <NavItem  href="/diskuze" text="DISKUZE" />
          </div>
        </div>
        {/* <div className={styles.hotnews}>
          <div className={styles.hotnewsHeader}>Konečně jsme spustili nový web!</div>
          <div className={styles.hotnewsContent}>
            Prozatím ještě chybí diskuze a možnost podívat se na výsledky předešlých ročníků (bude doděláno)<br />
            Pokud vám něco nefunguje nebo narazíte na chybu, nebojte se ozvat. Sportu zdar!
          </div>
        </div> */}
        {/* <div className={styles.hotnews}>
          <div className={styles.hotnewsHeader}>
            Ukončení sezóny
          </div>
          <div className={styles.hotnewsContent}>
            Ukončení sezóny proběhne v hospodě u Milušky<br />Pátek 30.6. v 18:00<br />
          </div>
        </div> */}
        <div className={styles.main}>
          <Routes>
            <Route path="/diskuze" element={<DiscussPage />} />
            <Route path="/liga/pravidla" element={<RulesPage />} />
            <Route path="/liga/tymy" element={<TeamsPage />} />
            <Route path="/liga/onas" element={<AboutPage />} />
            {/* <Route path="/ukonceni" element={<WelcomeView />} /> */}
            <Route path="*" element={<MainPage />} />
          </Routes>
        </div> 
      </div>
      <div className={styles.footer}>
        <span className={styles.footerLeft}>
          <a href="/admin/login">Admin</a>
        </span>

        <span>
          Design: Vít Baranec
        </span>
        <span>
          Web: Marek Hanzlik
        </span>
        <span>
          Děkujeme: Martin Hanzlík - tvůrce původního webu KFL
        </span>
        <span className={styles.footerRight}>
          2022-{footerYear}
        </span>
      </div>
    </>
  )
})

export default Frontend

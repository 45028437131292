import { action, computed, makeAutoObservable, makeObservable, observable } from "mobx";
import RootStore from "./RootStore"
import TransportLayer, { DiscussionItemDTO } from "./TransportLayer"

export class DiscussionStore {
    rootStore;
    transportLayer;
    discussionItems: DiscussionItemDTO[] = [];

    constructor(rootStore: RootStore, transportLayer: TransportLayer) {
      makeAutoObservable(this)
      this.transportLayer = transportLayer
      this.rootStore = rootStore
    }

    setDiscussions(items: DiscussionItemDTO[]) {
      this.discussionItems = items;
    }

    postComment(comment: DiscussionItemDTO) {
      this.transportLayer.postComment(comment)
        .then(() => {
          this.transportLayer.fetchDiscussion()
            .then((items) => {
              this.rootStore.discussion.setDiscussions(items)
            })
        })
    }
}

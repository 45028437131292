import { Models } from "appwrite";
import { makeAutoObservable } from "mobx";
import RootStore from "./RootStore"
import TransportLayer from "./TransportLayer"
import jwt_decode from "jwt-decode";
import { HTTPError } from "ky";

export interface JwtToken {
  exp: number
}

export class AuthStore {
    rootStore;
    transportLayer;
    initialized: boolean;
    
    jwt: string = "";

    constructor(rootStore: RootStore, transportLayer: TransportLayer) {
      makeAutoObservable(this)
      this.transportLayer = transportLayer
      this.rootStore = rootStore
      this.initialized = false
    }

    loadJwtFromLocalStorage() {
      const token = localStorage.getItem("jwtToken") || ""
      this.setToken(token)
    }

    saveJwtToLocalStorage() {
      localStorage.setItem("jwtToken", this.jwt)
    }

    isJwtValid(token: string): boolean {
      if(token.length == 0)
        return false
      
      const decoded = jwt_decode<JwtToken>(token)
      const nowTimestamp = Date.now() / 1000
      return decoded.exp > nowTimestamp
    }

    get isAuthenticated() {
      return this.isJwtValid(this.jwt);
    }

    get isInitialized() {
      return this.initialized;
    }

    setToken(token: string) {
      this.initialized = true;
      
      const valid = this.isJwtValid(token)
      if(valid)
        this.jwt = token
      else 
        this.jwt = ""

      this.saveJwtToLocalStorage()
    }

    private destroyToken() {
      this.setToken("")
    }

    async login(email: string, password: string, badEmailCallback: TransportLayer.VoidCallback, badPasswordCallback: TransportLayer.VoidCallback) {
      let token = ""
      
      try {
        token = await this.transportLayer.login(email, password)
      } catch (ex: any) {
        if (ex.name === 'HTTPError') {
          const httpError = ex as HTTPError
          const status = httpError.response.status
          if(status == 400) { badEmailCallback() }
          if(status == 401) { badPasswordCallback() }
        }
      }
      
      this.setToken(token)
    }

    logout() {
      this.destroyToken();
    }
}

import { computed } from "mobx";
import { observer, Observer } from "mobx-react-lite";
import React, { useContext } from "react";
import ReactDatePicker from "react-datepicker";
import { RootContext } from "../../../../../../App";
import styles from './FullShooterView.module.css'

export interface FullShooterViewProps {

}

const FullShooterView = observer((props: FullShooterViewProps) => {
  const rootContext = useContext(RootContext);

  const items: JSX.Element[] = []
  const unaccounted: JSX.Element[] = []
  rootContext.computedStore.getShooters().filter(a => a.player.name !== "Hráč nezapsán").sort((a,b) => a.goals < b.goals ? 1 : -1).map((p, idx) => {
    const team = rootContext.teamStore.getTeamName(p.player.teamId)
    const item = <Item key={p.player.name} goals={p.goals} name={p.player.name} position={idx+1} team={team} />
    items.push(item)
  })
  rootContext.computedStore.getShooters().filter(a => a.player.name === "Hráč nezapsán").sort((a,b) => a.goals < b.goals ? 1 : -1).map(p => {
    const team = rootContext.teamStore.getTeamName(p.player.teamId)
    const item = <ItemUnaccounted key={team} goals={p.goals} name={p.player.name} position={0} team={team} />
    unaccounted.push(item)
  })

  if(items.length == 0) {
    items.push(
      <tr key="empty" className={styles.tr}>
        <td colSpan={4}>Ještě nebyl vstřelen žádný gól</td>
      </tr>
    )
  }

  return (
    <React.Fragment>
      <table className={styles.table}>
        <tbody>
          <tr className={styles.tr}>
            <th className={styles.th}>#</th>
            <th className={styles.th}>JMÉNO</th>
            <th className={styles.th}>TÝM</th>
            <th className={styles.th}>GÓLY</th>
          </tr>
          {items}
        </tbody>
      </table>
      <table className={`${styles.table} ${styles.unaccountedTable}`}>
        <tbody>
          <tr className={styles.tr}>
              <th className={styles.th}>Nezapsané góly<br /> (nebyly v zápisu přiřazeny k hráči)</th>
          </tr>
          {unaccounted}
        </tbody>
      </table>
    </React.Fragment>
  )
})

export default FullShooterView;

interface ItemProps {
  position: number;
  name: string;
  team: string;
  goals: number;
}

function Item(props: ItemProps) {
  const getItemClass = (pos: number) => {
    switch(pos) {
      case 1:
        return styles.first;
      case 2:
        return styles.second;
      case 3:
        return styles.third;
      default:
        return ''
    }
  }

  return (
    <tr className={`${styles.tr} ${getItemClass(props.position)}`}>
      <td className={`${styles.td}  ${getItemClass(props.position)}`}>{props.position}.</td>
      <td className={styles.td}>{props.name}</td>
      <td className={styles.td}>{props.team}</td>
      <td className={styles.td}>{props.goals}</td>
    </tr>
  )
}

function ItemUnaccounted(props: ItemProps) {
  return (
    <tr className={`${styles.tr}`}>
      <td className={styles.td}>{props.team}</td>
      <td className={styles.td}>{props.goals}</td>
    </tr>
  )
}

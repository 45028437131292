import { observer } from "mobx-react-lite"
import { useContext } from "react"
import { RootContext } from "../../../../../../App"
import styles from "./GoalStatsView.module.css"

const GoalStatsView = observer(() => {
  const context = useContext(RootContext)
  const totalGoals = context.matchStore.calculateTotalGoals;
  const totalPlayers = context.playerStore.calculateTotalPlayers;
  const totalScorers = context.computedStore.getShooters().filter(p => p.player.name !== "Hráč nezapsán").length;

  return (
    <div className={styles.statsView}>
      <div className={styles.item}>
        <h3>POČET STŘELCŮ</h3>
        <h2>{totalScorers}</h2>
      </div>
      <div className={styles.item}>
        <h3>POČET GÓLŮ</h3>
        <h1>{totalGoals}</h1>
      </div><div className={styles.item}>
        <h3>POČET HRÁČŮ</h3>
        <h2>{totalPlayers}</h2>
      </div>
    </div>
  )
});

export default GoalStatsView;

import React, { useContext } from "react";
import {observer} from "mobx-react-lite";
import Header from "./Header";
import RosterBlock, { FieldEnum } from "./RosterBlock";
import styles from "./RosterView.module.css"
import Timer from "./Timer";
import dayjs from "dayjs";
import { MatchObject } from "../../../../../../state/MatchStore";
import { RootContext } from "../../../../../../App";
import { useNavigate } from "react-router-dom";


const RosterView = observer(() => {
  const navigate = useNavigate();
  const goToFullRoster = () => {
    navigate('/liga/rozpis')
  }
  let allMatches: Map<string, MatchObject[]> = new Map()
  const rootContext = useContext(RootContext);
  
  let dates: string[] = []
  
  for(var i in rootContext.matchStore.matches) {
    const match = rootContext.matchStore.matches[i];
    if(match.postponed)
      continue;

    const datetime = dayjs(match.time).tz();
    var date = datetime.format("DD. MM. YY")
    if(allMatches.has(date)) {
      allMatches.get(date)?.push(match);
    } else {
      allMatches.set(date, [match])
    }
    
    if(dates.includes(date) === false) {
      dates.push(date)
    }
  }
  
  dates.sort((a, b) => dayjs(a, "DD. MM. YY").tz() < dayjs(b, "DD. MM. YY").tz() ? -1 : 1)
  
  let firstUpcomingMatchTime = 0
  const today = dayjs()
  const upcoming: JSX.Element[] = dates.filter(date => {
    const dateObj = dayjs.utc(date, "DD. MM. YY")
    return today.subtract(1, "day").isBefore(dateObj, "day")
  })
  .map((date) => {
    const matchesForDay = allMatches.get(date)!;
    if(firstUpcomingMatchTime < today.unix()) {
      firstUpcomingMatchTime = dayjs(matchesForDay[0].time).unix();
    }
    return (
      <RosterBlock key={date} date={date} matches={matchesForDay} isOld={false} />
    )
  }).splice(0, 2)

  const played: JSX.Element[] = dates.filter(date => {
    const startOfDay = dayjs(date, "DD. MM. YY").startOf("day")
    const startOfToday = today.startOf("day")
    const isAfter = startOfToday.isAfter(startOfDay, "day")
    return isAfter
  })
  .map((date) => {
    const matches = allMatches.get(date)!;
    return (
      <RosterBlock key={date} date={date} matches={matches} isOld={true} />
    )
  }).reverse();

  const matchesWithScore = rootContext.matchStore.matches.filter(match => match.played);
  const totalMatchCount = rootContext.matchStore.matches.length;
  const endOfSeason = matchesWithScore.length == totalMatchCount && firstUpcomingMatchTime == 0
  return (
    <React.Fragment>
      <div className={styles.roster}>
        { upcoming.length > 0 && 
          <React.Fragment>
            <Header variant={0} text1="DALŠÍ ZÁPASY">
              <Timer nextMatchTimestamp={firstUpcomingMatchTime} />
            </Header>
            {upcoming}
            <div className={styles.footer}>
              <button className={styles.rosterButton} onClick={goToFullRoster}>ÚPLNÝ ROZPIS ZÁPASŮ {<span></span>}</button>
            </div>
          </React.Fragment>
        }
        <Header variant={1} text1={`${matchesWithScore.length} / ${totalMatchCount}`} text2={endOfSeason ? "KONEC SEZÓNY" : "ODEHRANÉ ZÁPASY"} />
        {played}
      </div>
    </React.Fragment>
  )
});

export default RosterView;

import { observer } from "mobx-react-lite";
import { useContext, useState } from "react";
import { RootContext } from "../../../../../App";
import Item, { TableItemProps } from "../SharedComponents/TableItem";
import TableWithButton from "../SharedComponents/TableWithButton";
import styles from "./ScoreTableView.module.css"
import React from "react"
import TeamForm from "./TeamForm";

interface ScoraTableViewProps {
  showForm: boolean;
}

const ScoreTableView = observer((props: ScoraTableViewProps) => {
  const rootContext = useContext(RootContext)
  
  const table = rootContext.matchStore.calculateScoreTable;
  const items = table?.sort((a,b) => { return a.points > b.points ? -1 : 1 }).map((it, idx) => {
    const teamName = rootContext.teamStore.getTeamName(it.team_id)
    const itemProps: CustomItemProps = {
      position: (idx + 1).toString(),
      text: teamName,
      value: it.points.toString(),
      logoSrc: '',
      showTeamForm: false,
      teamForm: <TeamForm teamName={teamName} />
    }
    
    return <CustomItem  {...itemProps} key={idx} showTeamForm={props.showForm} />;
  });
  
  return (
      <TableWithButton btnText="TÝMOVÁ TABULKA" btnDest="/tabulka" children={items}/>
  )
})

export default ScoreTableView;

export interface CustomItemProps {
  position: string;
  text: string;
  logoSrc: string;
  value: string;
  showTeamForm: boolean;
  teamForm: JSX.Element;
}

const CustomItem = (props: CustomItemProps) => {
  return (
    <div className={styles.item}>
      <div className={styles.abs}>
        <div className={styles.full}>
          <div className={`${styles.position} ${props.showTeamForm ? styles.positionHidden : ''}`}>
            {props.position}
          </div>
          <div className={`${styles.positionMarker} ${props.showTeamForm ? styles.positionMarkerHidden : ''}`}>
              |
          </div>
          <div className={`${styles.name} ${props.showTeamForm ? styles.nameShifted : ''}`}>
            {props.text}
          </div>
          <div className={`${styles.score} ${props.showTeamForm ? styles.scoreHidden : ''}`}>
            {props.value}
          </div>
        </div>
        <div className={`${styles.form} ${props.showTeamForm ? '' : styles.formHidden}`}>
          {props.teamForm}
        </div>
      </div>
    </div>
  )
}

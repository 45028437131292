import { observer } from 'mobx-react-lite';
import { useContext, useEffect, useRef, useState } from 'react';
import { RootContext } from '../../../../App';
import { DiscussionItemDTO } from '../../../../state/TransportLayer';
// import Picker from 'emoji-picker-react'
import styles from './DiscussPage.module.css'
import dayjs from 'dayjs';
import { makeAutoObservable } from 'mobx';

class DiscussPageStore {
  constructor() {
    makeAutoObservable(this)
  }

  replyingToId: string = ""
  replying: boolean = false

  reply(postId: string) {
    this.replying = true
    this.replyingToId = postId
  }

  cancelReply() {
    this.replying = false
    this.replyingToId = ""
  }
}

const store = new DiscussPageStore()

const DiscussPage = observer(() => {
  return (
    <div className={styles.page}>
      <div className={styles.header}>
        DISKUZE
      </div>
      <Discussion />
    </div>
  )
})

const Discussion = observer(() => {
  const rootContext = useContext(RootContext);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth"})
  }, [store.replying])

  const posts = store.replying == false ? rootContext.discussion.discussionItems : rootContext.discussion.discussionItems.filter(i => { return i.id == store.replyingToId || i.parentId == store.replyingToId })
  return (
    <div>
      <DiscussionForm />
      <div className={styles.postsContainer}>
        <Posts posts={posts.slice().sort((a,b) => {return a.timestamp > b.timestamp ? -1 : 1})} />
      </div>
    </div>
  )
})

interface DiscussionFormProps {
}

const DiscussionForm = (props: DiscussionFormProps) => {
  const discussionStore = useContext(RootContext).discussion
  const [name, _setName] = useState("")
  const [message, setMessage] = useState("")
  const [sent, setSent] = useState(false)
  const nameRef = useRef<HTMLInputElement>(null)
  const messageRef = useRef<HTMLTextAreaElement>(null)

  useEffect(() => {
    _setName(window.localStorage.getItem("discussionUserName") || "")
  }, [])

  useEffect(() => {
    if(store.replying) {
      if(name.length == 0) {
        nameRef.current?.focus()
      } else {
        messageRef.current?.focus()
      }
    }
  }, [store.replying])

  const setName = (val: string) => {
    window.localStorage.setItem("discussionUserName", val)
    _setName(val)
  }

  const sendComment = () => {
    discussionStore.postComment({
      id: "",
      parentId: store.replying ? store.replyingToId : null,
      timestamp: 0,
      name: name,
      message: message
    })

    setMessage("")
    setTimedSent()
  }

  const setTimedSent = () => {
    setSent(true)
    setTimeout(() => setSent(false), 5000)
  }

  return (
    <div className={styles.form}>
      <div className={styles.formName}>
        JMÉNO*
        <input ref={nameRef} onChange={e => setName(e.target.value)} value={name} />
      </div>
      <div className={styles.formTextArea}>
        VZKAZ*
        <textarea ref={messageRef} onChange={e => setMessage(e.target.value)} value={message} />
      </div>
      <div className={styles.formSubmit}>
        <button disabled={name.length == 0 || message.length == 0} className={styles.submitButton} onClick={() => {sendComment()}}>
          { sent ? "Odesláno" : store.replying ? "Odpovědět" : "Odeslat" }
        </button>
        { store.replying && 
          <button className={styles.submitButton} onClick={() => {store.cancelReply()}}>
            Zavřít odpověď
          </button>
        }
      </div>
      {/* <div>
        <EmojiView />
      </div> */}
    </div>
  )
}

// const EmojiView = () => {
//   const [show, setShow] = useState(false);
//   const picker = <Picker onEmojiClick={() => {}} groupNames={{
//     smileys_people: 'Smajlíci',
//     animals_nature: 'Příroda',
//     food_drink: 'Jídlo/Pití',
//     travel_places: 'Cestování',
//     activities: 'Aktivity',
//     objects: 'Věci',
//     symbols: 'Symboly',
//     flags: 'Vlajky',
//     recently_used: 'Naposledy použité',
//   }} />

//   return (
//     <div className={styles.emojiView}>
//       <button onClick={() => {setShow(!show)}}>Smajlik</button>
//       <div className={styles.emojiSelect}>
//         {show == true && picker}  
//       </div>
//     </div>
//   )
// }

const Posts = (props: PostsProps) => {
  const posts = props.posts.filter(it => { return it.parentId == null }).map(it => {
    const children = props.posts.filter(f => { return f.parentId == it.id})
    return (
      <div key={`d ${it.id}`}>
        { store.replying && 
          <div className={styles.replyingTo}>Odpovídáš na</div>
        }
        <DiscussionItem key={it.id} post={it} >
          {children.sort((a,b) => {return a.timestamp > b.timestamp ? 1 : -1}).map(c => { return (<DiscussionItem key={c.id} post={c} isChild />) })}
        </DiscussionItem>
      </div>
    )
  })

  return (
    <div>
      {posts}
    </div>
  )
}

interface PostsProps {
  posts: DiscussionItemDTO[];
}

interface DiscussionItemProps {
  post: DiscussionItemDTO
  children?: JSX.Element[]
  isChild?: boolean
}

const DiscussionItem = (props: DiscussionItemProps) => {
  const datetime = dayjs.unix(props.post.timestamp).locale("cs")
  const date = datetime.format("DD.MM.YYYY")
  const time = datetime.format("HH:MM")
  return (
    <div className={`${styles.discussionItem} ${ props.isChild ? styles.discussionItemChild : ''}`}>
      <div className={styles.itemContainer}>
        <div className={styles.itemName}>
          {props.post.name}
        </div>
        <div className={styles.itemDate}>
          <div className={styles.dateInline}>
            {date}
          </div>
          <div className={styles.timeInline}>
            {time}
          </div>
        </div>
        <div className={styles.itemMessage}>
          {props.post.message}
        </div>
        {!props.isChild && !store.replying && 
          <div className={styles.replyBtnContainer}>
            <button className={styles.replyBtn} onClick={() => store.reply(props.post.id)}>
              <div className={styles.replyIcon}></div>
              <span>Odpovědět</span>
            </button>
          </div>
        }
      </div>
      {props.children &&
        <div className={styles.reply}>
          {props.children}
        </div>
      }
    </div>
  )
}

export default DiscussPage;

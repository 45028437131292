import { observer } from 'mobx-react-lite'
import { useContext } from 'react'
import { RootContext } from '../../../../../../../App'
import ScorerItem from './ScorerItem'
import styles from './Scorers.module.css'

interface ScorersProps {
  wrapperRef: React.RefObject<HTMLDivElement>
  matchId: string
}

const Scorers = observer((props: ScorersProps) => {
  const rootContext = useContext(RootContext)
  const match = rootContext.matchStore.getMatchById(props.matchId)

  const team1ScorersData = new Map<number, number>()
  match?.team1Scorers?.forEach(it => {
    if(team1ScorersData.has(it)) {
      team1ScorersData.set(it, team1ScorersData.get(it)!! + 1)
    } else {
      team1ScorersData.set(it, 1)
    }
  })

  const team2ScorersData = new Map<number, number>()
  match?.team2Scorers?.forEach(it => {
    if(team2ScorersData.has(it)) {
      team2ScorersData.set(it, team2ScorersData.get(it)!! + 1)
    } else {
      team2ScorersData.set(it, 1)
    }
  })

  const team1Scorers = Array.from(team1ScorersData).map(([playerId ,goals]) => {
    const player = rootContext.playerStore.getPlayerById(playerId)!!
    return (
      <ScorerItem key={`${props.matchId}_${playerId}`} playerName={player.name} count={goals} />
    )
  })

  const team2Scorers = Array.from(team2ScorersData).map(([playerId,goals]) => {
    const player = rootContext.playerStore.getPlayerById(playerId)!!
    return (
      <ScorerItem key={`${props.matchId}_${playerId}`} playerName={player.name} count={goals} />
    )
  })

  return (
    <>
      <div ref={props.wrapperRef} className={styles.scorers}>
        <div className={styles.scorer1}>
          {team1Scorers}
        </div>
        <div className={styles.scorer2}>
          {team2Scorers}
        </div>
      </div>
    </>
  )
})

export default Scorers

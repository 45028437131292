import { action, makeAutoObservable, makeObservable, observable } from "mobx";
import { MatchStore } from "./MatchStore";
import { PlayerStore } from "./PlayerStore";
import TransportLayer from "./TransportLayer";
import { WeatherStore } from "./WeatherStore";
import { TeamInfoDTO, TeamStore } from "./TeamStore";
import { DiscussionStore } from "./DiscussionStore";
import { AuthStore } from "./AuthStore";
import { ComputedStore } from "./ComputedStore";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";

class YearStore {
  year: number = 2024;
  years: number[] = [2024]
  transport: TransportLayer


  constructor() {
    makeAutoObservable(this)

    this.transport = new TransportLayer();
    this.transport.fetchMatchYears().then(res => {
      this.setYears(res)
    })
  }

  setYear(year: number) {
    this.year = year
  }

  setYears(years: number[]) {
    this.years = years
  }
}

const YearWatcher = observer(() => {
  const [prevYear, setPrevYear] = useState(2024)
  
  useEffect(() => {
    if(prevYear == yearStore.year)
      return

    console.log("changing year")
    const matches = rootStore.transport.fetchMatches().then(res => {
      rootStore.matchStore.setMatches(res)
      setPrevYear(yearStore.year)
    })
  }, [yearStore.year])

  return (
    <div></div>
  )
})

export default class RootStore {
  matchStore: MatchStore;
  weatherStore: WeatherStore;
  playerStore: PlayerStore;
  teamStore: TeamStore;
  discussion: DiscussionStore;
  authStore: AuthStore;

  computedStore: ComputedStore;

  transport: TransportLayer;

  constructor() {
    makeObservable(this, {
      matchStore: observable,
      weatherStore: observable,
      playerStore: observable,
      teamStore: observable,
      discussion: observable,
      authStore: observable,
    });

    this.transport = new TransportLayer();
    
    this.teamStore = new TeamStore(this, this.transport);
    this.matchStore = new MatchStore(this, this.transport);
    this.weatherStore = new WeatherStore(this, this.transport);
    this.playerStore = new PlayerStore(this, this.transport);
    this.discussion = new DiscussionStore(this, this.transport);
    this.authStore = new AuthStore(this, this.transport);

    const pTeams = this.transport.fetchTeams();
    const pMatches = this.transport.fetchMatches();
    const pTemps = this.transport.fetchTemperatures();
    const pPlayers = this.transport.fetchPlayers();
    const pDiscussion = this.transport.fetchDiscussion();

    Promise.all([pTeams, pMatches, pTemps, pPlayers, pDiscussion]).then(([teams, matches, temps, players, discussions]) => {
      this.teamStore.setTeams(teams);
      this.playerStore.setPlayers(players);
      this.matchStore.setMatches(matches);
      this.weatherStore.setForecast(temps);
      this.discussion.setDiscussions(discussions);
    })
    
    this.authStore.loadJwtFromLocalStorage();
    this.computedStore = new ComputedStore(this)
  }
}

let yearStore: YearStore = new YearStore();
let rootStore: RootStore = new RootStore();
export { rootStore, yearStore, YearWatcher };

import dayjs from "dayjs";
import { makeAutoObservable, runInAction, reaction } from "mobx"
import RootStore from "./RootStore"
import TransportLayer from "./TransportLayer"

export class WeatherStore {
    rootStore;
    transportLayer;
    weather: WeatherForecastData = new WeatherForecastData([], [], []);

    constructor(rootStore: RootStore, transportLayer: TransportLayer) {
      makeAutoObservable(this);
      this.transportLayer = transportLayer;
      this.rootStore = rootStore;
    }

    setForecast(weather: WeatherForecastData) {
      this.weather = weather;
    }

    getWeatherForDatetime(unixTimestamp: number): WeatherForecast {
      let t = dayjs.unix(unixTimestamp).tz();
      if(t.minute() != 0) {
        t = t.subtract(t.minute(), 'minute');
      }
      const formated = t.format('YYYY-MM-DDTHH:mm'); 
      const idx = this.weather.times.findIndex(it => { 
        return it == formated;
      });

      if(idx > -1) {
        return new WeatherForecast(
          this.weather.times[idx],
          this.weather.temps[idx],
          this.weather.codes[idx]
        );
      }

      return new WeatherForecast("", 0, -1);
    }
}

export class WeatherForecast {
  time: string;
  temp: number;
  code: number;

  constructor(time: string, temp: number, code: number) {
    this.time = time;
    this.temp = temp;
    this.code = code;
  }
}

export class WeatherForecastData {
  times: string[];
  temps: number[];
  codes: number[];

  constructor(times: string[], temps: number[], codes: number[]) {
    this.times = times;
    this.temps = temps;
    this.codes = codes;
  }
}

import React, { useRef, useEffect } from "react";

export default function useDocumentClickHandler(ref: React.RefObject<HTMLDivElement>, callback: () => void) {
  useEffect(() => {

    function handleClickOutside(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        callback();
      }
    }

    document.addEventListener("mouseup", handleClickOutside);

    return () => {
      document.removeEventListener("mouseup", handleClickOutside);
    };
  }, [ref]);
}
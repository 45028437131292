var charMapL = " 0123456789aábcčdďeéěfghiíjklmnňoópqrřsštťuúůvwxyýzž";
var charMapU = " 0123456789AÁBCČDĎEÉĚFGHIÍJKLMNŇOÓPQRŘSŠTŤUÚŮVWXYÝZŽ";
var charsOrder: { [char: string]: number } = {};
for (var i in charMapL.split('')) {
  charsOrder[charMapL[i]] = parseInt(i);
  charsOrder[charMapU[i]] = parseInt(i);
}


function czSort(s1: string, s2: string) {
  var idx = 0;
  while ((idx < s1.length) && (idx < s2.length) && (charsOrder[s1[idx]] == charsOrder[s2[idx]])) {
    idx++;
  }
  if ((idx == s1.length) && (idx == s2.length)) return 0;
  if (idx == s1.length) return 1;
  if (idx == s2.length) return -1;
  return charsOrder[s1[idx]] > charsOrder[s2[idx]] ? 1 : (charsOrder[s1[idx]] < charsOrder[s2[idx]] ? -1 : 0);
}

export default czSort
import { makeAutoObservable, runInAction, reaction } from "mobx"
import RootStore from "./RootStore"
import TransportLayer, { MatchObjectDTO, TeamScoreDTO } from "./TransportLayer"

export class MatchStore {
    rootStore;
    transportLayer;
    matches: MatchObject[] = [];

    constructor(rootStore: RootStore, transportLayer: TransportLayer) {
      makeAutoObservable(this)
      this.transportLayer = transportLayer
      this.rootStore = rootStore
    }

    setMatches(matches: MatchObject[]) {
      this.matches = matches;
    }

    createMatch(match: MatchObject) {
      this.matches.push(match)
      return match;
    }

    updateMatch(match: MatchObject) {
      const index = this.matches.findIndex(m => m.uid === match.uid)
      if(index > -1){
        this.matches[index] = match;
      }

      this.transportLayer.updateMatch(match)
    }

    removeMatch(match: MatchObject) {
      this.matches.splice(this.matches.indexOf(match), 1)
    }

    getMatchById(matchId: string) : MatchObject | undefined {
      return this.matches.find(it => it.uid === matchId)
    }

    get calculateYears(): number[] {
      const years: number[] = []
      this.matches.forEach(match => {
        if(years.indexOf(match.year) == -1) {
          years.push(match.year)
        }
      })

      return years.sort((a,b) => b - a)
    }

    get calculateScoreTable(): TeamScoreDTO[] {
      const teams = new Map<number, TeamScoreDTO>();

      this.matches.forEach(match => {
        if(!teams.has(match.team1Id)) teams.set(match.team1Id, new TeamScoreDTO(match.team1Id))
        if(!teams.has(match.team2Id)) teams.set(match.team2Id, new TeamScoreDTO(match.team2Id))
      })

      this.matches
        .filter(match => { return match.played })
        .forEach(match => {
          const isDraw = match.score1 == match.score2;
          const winner = match.score1 > match.score2 ? match.team1Id : match.team2Id;
          const loser = match.score1 > match.score2 ? match.team2Id : match.team1Id;

          teams.get(match.team1Id)!.matches += 1;
          teams.get(match.team1Id)!.goals_given += match.score1;
          teams.get(match.team1Id)!.goals_taken += match.score2;

          teams.get(match.team2Id)!.matches += 1;
          teams.get(match.team2Id)!.goals_given += match.score2;
          teams.get(match.team2Id)!.goals_taken += match.score1;

          if(isDraw) {
            teams.get(match.team1Id)!.points += 1;
            teams.get(match.team1Id)!.draws += 1;

            teams.get(match.team2Id)!.points += 1;
            teams.get(match.team2Id)!.draws += 1;
          } else {
            teams.get(winner)!.points += 3;
            teams.get(winner)!.won += 1;
            teams.get(loser)!.lost += 1;
          }
        })

      return Array.from(teams.values());
    }

    get calculateTotalGoals(): number {
      let goals = 0;
      this.matches.forEach(it => {
        if(it.played) {
           goals += it.score1;
           goals += it.score2;
        }
      })

      return goals;
    }
}

// "uid": "01GRW24G12C1DM3RMHZ8KKWJWZ",
// "score1": 2,
// "score2": 3,
// "team1Id": 3,
// "team2Id": 4,
// "team1Scorers": [],
// "team2Scorers": [],
// "round": 1,
// "time": 1662879600.000000000,
// "field": "Benzina",

export class MatchObject {
  uid: string
  score1: number
  score2: number
  team1Id: number
  team2Id: number
  team1Scorers: number[]
  team2Scorers: number[]
  round: number
  time: string
  field: string
  referee: string
  played: boolean
  contumacy: boolean
  postponed: boolean
  year: number

  constructor(
    uid: string = "",
    round: number = 0,
    time: string = "",
    field: string = "",
    gameScore1: number = -1,
    gameScore2: number = -1,
    team1Id: number = -1,
    team2Id: number = -1,
    team1Scorers: number[] = [],
    team2Scorers: number[] = [],
    referee: string = "",
    played: boolean = false,
    contumacy: boolean = false,
    postponed: boolean = false,  
    year: number = 0
  )
  {
    this.uid = uid
    this.round = round
    this.time = time
    this.field = field
    this.score1 = gameScore1
    this.score2 = gameScore2
    this.team1Id = team1Id
    this.team2Id = team2Id
    this.team1Scorers = team1Scorers
    this.team2Scorers = team2Scorers
    this.referee = referee
    this.played = played
    this.contumacy = contumacy
    this.postponed = postponed
    this.year = year
  }

  // ToMatchObjectAsString(): MatchObjectAsString {
  //   let obj = new MatchObjectAsString()
  //   obj.$id = this.uid
  //   obj.round = this.round
  //   obj.time = this.time
  //   obj.field = this.field
  //   obj.gameScore1 = this.score1
  //   obj.gameScore2 = this.score2
  //   obj.team1_id = this.team1Id
  //   obj.team2_id = this.team2Id
  //   obj.team1_scorers = JSON.stringify(this.team1Scorers)
  //   obj.team2_scorers = JSON.stringify(this.team2Scorers)

  //   return obj;
  // }
}

import { createContext } from 'react'
import { Routes, Route } from 'react-router-dom'
import styles from './App.module.css'
import Frontend from './Displays/Frontend/Frontend'
import RootStore, { rootStore } from './state/RootStore'
import Backoffice from './Displays/Backoffice/BackofficePage'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone' 
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { useMediaQuery } from "react-responsive";
import FrontendMobile from './Displays/Frontend/FrontendMobile'
import { YearWatcher } from './state/RootStore'

dayjs.extend(customParseFormat)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault("Europe/Prague")

export const RootContext = createContext<RootStore>(rootStore)

export const App = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 1224px)'})
  return(
    <RootContext.Provider value={rootStore}>
      <div className={styles.app}>
        <YearWatcher />
        <Routes>
            <Route path="/admin/*" element={<Backoffice />} />
            { isMobile == false && <Route path="*" element={<Frontend />} /> }
            { isMobile && <Route path="*" element={<FrontendMobile />} /> }
        </Routes>
      </div>  
    </RootContext.Provider>
  )
}

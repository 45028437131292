import styles from './Scorers.module.css'
import ball from '../../../../../../../images/ball.png'

interface ScorerItemProps {
  //type: goal, card etc..
  playerName: string
  count: number
}

const ScorerItem = (props: ScorerItemProps) => {
  return (
    <>
      <div className={styles.scorer}>
        <img src={ball} />
        {props.count}x {props.playerName}
      </div>
    </>
  )
}

export default ScorerItem

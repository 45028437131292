import React from "react";
import { useState } from "react";
import ScoreTableView from "./ScoreTableView";
import styles from "./TableView.module.css"
import TeamForm from "./TeamForm";

const TableView = () => {
  const [activeTabIndex, setActiveTabIndex] = useState(0)

  const onIndexClick = (index: number) => {
    setActiveTabIndex(index);
  }

  const tabs = [
    "TABULKA",
    "FORMA"
  ]

  const jsxTabs: JSX.Element[] = []
  for(let i = 0; i < tabs.length; i++) {
    const active: boolean = i === activeTabIndex
    jsxTabs.push(<Tab key={i} active={active} text={tabs[i]} onClick={() => {onIndexClick(i)}}  />);
  }

  return (
    <div className={styles.view}>
      <div className={styles.tabs}>
        {jsxTabs}
      </div>
      <div>
        <ScoreTableView showForm={activeTabIndex == 1} />
      </div>
    </div>
  )
}

interface TabProps {
  active: boolean;
  text: string;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}

function Tab(props: TabProps) {
  return (
    <React.Fragment>
      <button className={props.active ? styles.active : styles.inactive} onClick={props.onClick} >{props.text}</button>
    </React.Fragment>
  )
}

export default TableView;

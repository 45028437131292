import React from "react";
import GoalStatsView from "./GoalStatsView/GoalStatsView";
import ShooterView from "./ShooterView/ShooterView";

const RightColumnView = () => {
  return (
    <React.Fragment>
      <ShooterView />
      <GoalStatsView />
    </React.Fragment>
  )
}

export default RightColumnView;
import styles from './RulesPage.module.css'
import netImage from '../../../../images/net.png'

export default function RulesPage() {
  const registrationFee = 200
  return (
    <div className={styles.page}>
      <div className={styles.header}>PRAVIDLA</div>
      <div className={styles.headerImg}><img src={netImage} /></div>

      <div className={styles.part}>
        Kobeřické fotbalové ligy se mohou zúčastnit hráči, kteří: 
      </div>
      <div className={styles.partContent}>
        <span className={styles.span}><b>1)</b> Jsou rodáky Kobeřic, nebo se do Kobeřic přiženili, přistěhovali a mají zde své trvalé bydliště.</span>
        <span className={styles.span}><b>2)</b> Nejsou profesionálně hrajícími hráči mužstev fotbalových klubů, nejen Kobeřic, ale i jiných klubů zodpovídajících se svazu.</span>
        <span className={styles.span}><b>3)</b> Překročili věk 38 let, i když je pořád profesionálně hrajícími hráčem mužstva fotbalového klubů zodpovídajícího se svazu.</span>
        <span className={styles.span}><b>4)</b> Mají řádně zaplaceno startovné v hodnotě {registrationFee} Kč.</span>
        <span className={styles.span}><b>5)</b> Ukončí své působení v mužstvu fotbalového klubu a nehodlají pokračovat ve fotbalové hře za klub organizovaný svazem. Je požadována 
          doba nejméně jednoho roku od ukončení ve fotbalovém klubu. Nastoupit, k prvnímu utkáni v Kobeřické fotbalové lize, může až po uplynutí 1 roku.</span>
      </div>
      <div className={styles.partContent}>
        Na soupisce mužstev mohou být napsáni i hráči z jiných měst a vesnic v neomezeném počtu. Zápas ovšem mohou hrát jen dva hráči z jiného města nebo vesnice. Pro tyto hráče platí stejné podmínky, výše uvedené v bodech 2-4.
      </div>
      <div className={styles.part}>
        Pravidla:
      </div>
      <div className={styles.partContent}>
        <span className={styles.span}><b>1)</b> Hrajeme na hřišti přibližných rozměrů malé kopané. Hrací čas je 2 x 30 minut hrubého času + nastavení dle úvahy rozhodčího max. 5 minut, rozhodčí nesmí čas utkání zkrátit. Poločas má dobu trvání 15 minut. Od poločasové přestávky lze na přání obou družstev upustit. Čekací doba na soupeře je 15 minut od stanoveného začátku utkání.</span>
        <span className={styles.span}><b>2)</b> Maximální počet - šest hráčů + brankář. Každé družstvo smí vystřídat všechny hráče připravené k utkání a uvedené v Zápise o utkání. Střídání probíhá hokejově „hráč za hráče“, během fotbalového utkání. Střídající hráči střídají na délku natažených rukou. Pokud tomu tak není, rozhodčí může udělit nastupujícímu hráči žlutou kartu za nedodržení pravidel plynulé hry.</span>
        <span className={styles.span}><b>3)</b> Počet hráčů uvedených v Zápise o utkání je minimálně pět (čtyři a brankář), maximální počet není omezen. Všichni uvedení hráči (tedy i hráči na střídačce) podléhají pravomoci rozhodčího po celou dobu utkání (od jeho zahájení až do podepsání Zápisu o utkání, tj. i v poločasové přestávce). Vyloučen proto může být i hráč ze střídačky.</span>
        <span className={styles.span}><b>4)</b> Družstvo, které nastoupilo s menším počtem hráčů (čtyři a brankář), má právo jejich počet kdykoliv v přerušené hře a se souhlasem rozhodčího doplnit a zároveň dopsat do Zápisu o utkání.</span>
        <span className={styles.span}><b>5)</b> Neexistuje postavení mimo hru. Brankář je povinen auty odkopávat ze země, nesmí vyhazovat ani vykopávat z ruky.</span>
        <span className={styles.span}><b>6)</b> Hráči družstva, které neprovádí přímý kop, musí být minimálně 5 metrů od míče. Při porušení vzdálenosti může rozhodčí nechat kop opakovat a hráče, který nedodržel vzdálenost, napomene žlutou kartou. Pokutový kop se provádí ze vzdálenosti 7 metrů od středu branky. Hráč jež kope přímý nebo pokutový kop, musí počkat na pokyn rozhodčího, tedy hvizd píšťalky.</span>
        <span className={styles.span}><b>7)</b> Auty se musí vhazovat s balonem pevně uchopeným v obou dlaních za hlavou a bez přešlapů a výskoků.</span>
        <span className={styles.span}><b>8)</b> Malá domů NENÍ POVOLENA ( změna oproti minulým rokům). Řídí se podle pravidel klasického velkého fotbalu. Pokud brankář přestupek udělá a míč chytne do ruky, následuje nepřímý kop.</span>
        <span className={styles.span}><b>9)</b> Brankář může vyhodit míč rukou za polovinu hřiště !!! ( Změna oproti minulým rokům ). Brankář může místo výhozu použít výkop(pouze, když chytne míč ve hře). Pokud použije tzv. half volej nebo výkop z ruky může tímto výkopem dosáhnout i branky.</span>
        <span className={styles.span}><b>10)</b> Soupisky týmu je možno rozšířit i během roku. Ovšem, až po zaplacení poplatku { registrationFee } Kč za hráče. Přestupy hráčů jsou povoleny i během roku s poplatkem { registrationFee } Kč.</span>
      </div>
      <div className={styles.part}>
        Povinnosti rozhodčích:<br />
      </div>
      <div className={styles.partContent}>
        <span className={styles.span}>Nejsou jmenovitě ustanovení rozhodčí. V rozpise zápasů jsou stanoveny termíny zápasů. Rozpis je doručen do čtvrtku nadcházejícího víkendu na kapitány a zástupce každého mužstva. Ke každému zápasu je dopsáno mužstvo, které bude zápas rozhodovat. Hráči rozhodujícího mužstva se mezi sebou domluví, a je povinen jeden hráč z tohoto mužstva odpískat zápas, ke kterému byl napsán. Pokud se nedostaví žádný rozhodčí z mužstva určeného pískat uložený zápas, bude toto mužstvo pokutováno 100 Kč. V kolonce „Rozhodčí“, v „zápise o utkání“ (příloha č. 1)bude dopsána pokuta týmu, jehož rozhodčí se nedostavil, a za pokutovaným týmem rozhodčí, jež zápas rozhodoval. Rozhodčí jsou povinni, mít na sobě rozlišovací dres a pohybovat se po hřišti tak, aby měl přehled o autech. Rozhodčí jsou povinni dále, zapisovat vstřelené branky a udělené karty během zápasu. Musí být seznámen s pravidly.</span>
        <b>Rozhodčí vyloučí hráče, jestliže mj.:</b>
        <span className={styles.span}><b>1)</b> se dopustí hrubého faulu</span>
        <span className={styles.span}><b>2)</b> chová se hrubě nesportovně (např. plivne na protihráče nebo rozhodčího)</span>
        <span className={styles.span}><b>3)</b> používá hrubých a urážlivých výroků</span>
        <span className={styles.span}><b>4)</b> úmyslně zamezí rukou či faulem dosažení branky</span>
        <span className={styles.span}><b>5)</b> dopustí se přestupku, za který může být napomenut a předtím již napomínán byl žlutou kartou</span>
      </div>
      <div className={styles.part}>
        Volné kopy se dělí na:<br />
      </div>
      <div className={styles.partContent}>
        <span className={styles.parent}><b>1)</b> Přímý volný kop, z něhož lze přímo, tj. bez teče, dosáhnout branky, se nařizuje, pokud hráč:</span>
        <span className={styles.child}><b>a)</b> za faul na protihráče (podražení, za nebezpečnou hru a jiné dle úvahy rozhodčího)</span>
        <span className={styles.child}><b>b)</b> násilně odstrčí soupeře stažení hráče</span>
        <span className={styles.child}><b>c)</b> skočí na soupeře</span>
        <span className={styles.child}><b>d)</b> vrazí do soupeře</span>
        <span className={styles.child}><b>e)</b> udeří soupeře</span>
        <span className={styles.child}><b>f)</b> strčí do soupeře</span>
        <span className={styles.child}><b>g)</b> při skluzu se soupeře dotkne dříve než míče nebo zasáhne soupeře, přestože se dotkl míče dříve</span>
        <span className={styles.child}><b>h)</b> drží soupeře</span>
        <span className={styles.child}><b>i)</b> úmyslně hraje míč rukou (vyjma brankáře ve vlastním brankovém území)</span>
        <span className={styles.parent}><b>2)</b> Nepřímý volný kop, z něhož nemůže být přímo dosaženo branky, se nařizuje za:</span>
        <span className={styles.child}><b>a)</b> bránění ve hře</span>
        <span className={styles.child}><b>b)</b> nebezpečnou hru vůči soupeři</span>
        <span className={styles.child}><b>c)</b> nesportovní chování</span>
        <span className={styles.child}><b>d)</b> technické přestupky brankáře</span>
      </div>
      <div className={styles.part}>
        Pokuty a tresty:<br />
      </div>
      <div className={styles.partContent}>
        <span className={styles.block}>
          Za červenou kartu stop 1 kolo a za dvě červené pokuta 200Kč v jedné sezoně u jednoho hráče. Čtyři žluté karty, stop na jedno kolo a pokuta 100 Kč. Po dalších čtyřech žlutých, stop na 2. zápasy a pokuta 300 Kč. Zákaz hry se převádí i do následující sezony. Jinak se ukončením ročníku ruší.
        </span>
        <span className={styles.block}>
          Termíny zápasu jsou stanoveny, a jsou doručeny se 14 -cti denním předstihem na kapitány, nebo na kontaktní osoby jednotlivých mužstev. Muže se odložit celé kolo na jiný termín a to z důvodu nezpůsobilého terénu, nezajištění hrací plochy, pořádaní nějaké kulturní akce obecním úřadem.
        </span>
        <span className={styles.block}>
          Mužstva jsou povinny k zápasu nastoupit za každého počasí. Na místě konání zápasu se rozhodne, bude-li zápas odehrán, nebo bude odložen na jiný termín. Tento termín si mužstva dohodnou mezi sebou, v den odložení zápasu. Termín nahlásí pořadatelům.
        </span>
        <span className={styles.block}>
          Je povinností jednotlivých mužstev, aby své zápasy odehrály v daném termínu. Pokud některé z mužstev nebude mít dostatek hráčů, musí nahlásit svou nepřítomnost nejpozději do čtvrtku 12:00, aspoň jednomu z pořadatelů. Pokud tak neučiní, bude penalizován pokutou 200 Kč. Zápas bude zapsán jako kontumačně prohrán s výsledkem 5:0 pro zúčastněné mužstvo.<br />
        </span>
        <span className={styles.block}>
          Odložit se nedá žádný zápas. Zápas musí být předehrán, nebo odehrán do následujícího kola. Termín musí být nahlášen vedení KFL do pátku 12:00 a mužstvo, které zápas přesunuje musí být domluveno se soupeřem (soupeř musí souhlasit).Nesouhlasí-li soupeř, zápas musí být odehrán v daném termínu, nebo bude zapsán kontumačně 0:5
        </span>
        <span className={styles.block}>
          Hřiště budou označena v rozpise zápasů písmeny "B" (benzinka) - hraje se blíže benzinky. "Z" (Ziegel) - hraje se blíže Ziegla. Je povinností toto dodržovat.<br />
        </span>
        <span className={styles.block}>
          Branky jsou zamčeny u buňky s nářadím. Mužstva která začínají první zápas v sobotu či neděli branky ustaví. Mužstva která končí hrací den jsou povina branky uklidit a zamknout. Neučiníli tak, budou pokutována částkou 500 kč.<br />
        </span>
        <span className={styles.block}>
          Pokutou 100 Kč bude penalizováno špatné vyplnění „zápisu o utkání“. Penalizováno bude mužstvo, jež nezapíše všechny hráče hrající zápas, po konci zápasu ručí svým podpisem za správnost vyplnění „zápisu o utkání“. Rozhodčí, ručí za zapsání střelců do zápisu a výsledek zápasu. Pokud rozhoduje zápas rozhodčí z tymu, které nebylo určeno k pískání daného zápasu, je povinen dopsat do závorky pokutu týmu, jež se nezúčastnilo rozhodování zápasů (příklad: V „zápise o utkání“ kolonce „rozhodčí“ (pok.: nezúčastněný tým) jméno rozhodčího),<br />
        </span>
        <span className={styles.block}>
          Hlavní rozhodčí rozhoduje po celý fotbalový zápas, napadení bude trestáno, jak pokutou hráče, jež napadl rozhodčího, tak celého mužstva a to prohrou 5:0 za jakéhokoli stavu zápasu. Napadení rozhodčího se trestá pokutou od 500 Kč na mužstvo.<br />
        </span>
        <span className={styles.block}>
          Pokud se dvě mužstva pobijí, o jejich setrvání v soutěži rozhodne, tresty a pokuty určí, zasedání kapitánů a zástupců mužstev.<br />
        </span>
        <span className={styles.block}>
          Případné úpravy v pravidlech, se budou doplňovat a uveřejňovat vždy po ukončení letní nebo podzimní sezony.<br />
        </span>
        <span className={styles.block}>
          Výsledky se budou hodnotit v tomto pořadí, BODY – VZÁJEMNÝ ZÁPAS – SKÓRE.<br />
        </span>
        <span className={styles.block}>
          Kobeřická fotbalová liga není zodpovědná za zranění, které se stanou během turnajů. Tedy je nenožné, po pořadatelích, žádat jakékoli odškodné, za způsobené zranění a škodu spojenou s jinými náhradami.
        </span>
      </div>
    </div>
  )
}

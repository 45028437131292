import dayjs from "dayjs";
import React, { FocusEvent, useContext, useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker"
import { MatchObject } from "../../../../state/MatchStore";
import Select from "react-select";

import styles from "./AddMatchBox.module.css"
import { RootContext } from "../../../../App";

interface AddMatchBoxProps {
  match: MatchObject | undefined;
  onSubmit(match: MatchObject): void;
}

export default function AddMatchBox(props: AddMatchBoxProps) {
  const context = useContext(RootContext);
  const teamStore = context.teamStore;
  const playerStore = context.playerStore;

  const defaultDate = dayjs(props.match?.time || new Date());
  defaultDate.add(9, 'hour')

  const [round, set_round] = useState(props.match?.round || 0)
  const [t1_score, set_t1_score] = useState(props.match?.score1 || -1)
  const [t2_score, set_t2_score] = useState(props.match?.score2 || -1)
  const [t1_scorers, set_t1_scorers] = useState(props.match?.team1Scorers || [])
  const [t2_scorers, set_t2_scorers] = useState(props.match?.team2Scorers || [])
  const [date, set_date] = useState(dayjs(props.match?.time) || defaultDate)
  const [team1_id, set_team1_id] = useState(props.match?.team1Id || -1)
  const [team2_id, set_team2_id] = useState(props.match?.team2Id || -1)
  const [selectedField, setSelectedField] = useState(false)

  useEffect(() => {
    if(props.match == null) return;

    set_round(props.match.round)
    set_t1_score(props.match.score1)
    set_t2_score(props.match.score2)
    set_t1_scorers(props.match.team1Scorers)
    set_t2_scorers(props.match.team2Scorers)
    set_team1_id(props.match.team1Id)
    set_team2_id(props.match.team2Id)
    set_date(dayjs(props.match.time))
  }, [props.match])

  
  const fieldSelectClass1 = `${styles.fieldItem} ${!selectedField ? styles.active : ''}`
  const fieldSelectClass2 = `${styles.fieldItem} ${selectedField ? styles.active : ''}`

  const options = teamStore.teams.map(team => {
    return {
      value: team.id, label: team.name
    }
  })

  let t1Shooters: JSX.Element[] = [];
  let t2Shooters: JSX.Element[] = [];

  const selectStyles = {
    container: (base: any) => ({
      ...base,
      flex: 1,
    })
  };
  

  const t1Idx = options.findIndex(o => { return o.value == team1_id })
  const t2Idx = options.findIndex(o => { return o.value == team2_id })
  

  const resizeArray = (array: number[], size: number) => {
    const arr = array.splice(0, size)
    return arr
  }

  const t1ShooterOpts = playerStore.players
    .filter(p => p.teamId == team1_id)
    .map(p => {
      return { value: p.id, label: p.name }
    })
    .sort((a,b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0))
  const t2ShooterOpts = playerStore.players
    .filter(p => p.teamId == team2_id)
    .map(p => {
      return { value: p.id, label: p.name }
    })
    .sort((a,b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0))

  const onT1ShooterChange = (idx: number, playerId: number) => {
    const arr = resizeArray(t1_scorers, t1_score)
    arr[idx] = playerId;
    set_t1_scorers(arr)
  }
  const onT2ShooterChange = (idx: number, playerId: number) => {
    const arr = resizeArray(t2_scorers, t2_score)
    arr[idx] = playerId;
    set_t2_scorers(arr)
  }
  
  if(t1_score > 0) {
    for(let i = 0; i < t1_score; i++) {
      const opt = t1_scorers.length > i ? {value: t1_scorers[i], label: playerStore.getPlayerById(t1_scorers[i])?.name} : undefined
      t1Shooters.push(<Select key={`${i}_t1_${team1_id}_${round}`} defaultValue={opt} options={t1ShooterOpts} onChange={(it) => onT1ShooterChange(i, it!!.value!!)} />)
    }
    
    for(let i = 0; i < t2_score; i++) {
      const opt = t2_scorers.length > i ? {value: t2_scorers[i], label: playerStore.getPlayerById(t2_scorers[i])?.name} : undefined
      t2Shooters.push(<Select key={`${i}_t2_${team2_id}_${round}`} defaultValue={opt} options={t2ShooterOpts} onChange={(it) => onT2ShooterChange(i, it!!.value)} />)
    }
  }

  const handleFocus = (event: FocusEvent<HTMLInputElement>) => {
    event.target.select();
    event.preventDefault();
  }


  return (
    <div className={styles.container}>
      <div className={styles.header}>

      </div>
      <div className={styles.content}>
        
      </div>
    </div>
  )
}

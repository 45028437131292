import { makeAutoObservable } from "mobx";
import RootStore, { rootStore } from "./RootStore"
import { PlayerDTO } from "./TransportLayer";

export class ComputedStore {
    rootStore;

    constructor(rootStore: RootStore) {
      makeAutoObservable(this)
      this.rootStore = rootStore
    }

    getShooters() {
      const matches = rootStore.matchStore.matches
    
      const shooters = new Map<number, number>()
      matches.filter(match => match.played).forEach(match => {
        const all = match.team1Scorers.concat(match.team2Scorers)
        all.forEach(p => {
          if(shooters.has(p)) {
            shooters.set(p, shooters.get(p)!! + 1)
          } else {
            shooters.set(p, 1)
          }
        })
      })
    
      const arr: ShooterData[] = []
      shooters.forEach((k,v) => {
        arr.push({
          player: this.rootStore.playerStore.getPlayerById(v)!!,
          goals: k
        })
      })

      return arr
    }
}

export interface ShooterData { 
  player: PlayerDTO,
  goals: number
}

import { observer } from "mobx-react-lite";
import { useContext } from "react";
import { RootContext } from "../../../../../../App";
import TableWithButton from "../../SharedComponents/TableWithButton";
import Item, { SimpleItem } from "../../SharedComponents/TableItem";
import styles from './ShooterView.module.css'

const ShooterView = observer(() => {
  const context = useContext(RootContext)
  const items = context.computedStore.getShooters()
      .filter(i => i.player.name !== "Hráč nezapsán")
      .sort((a,b) => a.goals < b.goals ? 1 : -1)
      .slice(0, 8).map((item, idx) => {
        const itemProps = { position: (idx+1).toString(), text: item.player.name, value: item.goals.toString(), logoSrc: "" }
        return <Item {...itemProps} key={idx} />
      })

  if(items.length == 0) {
    items.push(
      <SimpleItem key="ShooterView_no_goal" text="Ještě nebyl vstřelen žádný gól" />
    )
  }

  return (
    <div className={styles.view}>
      <div className={styles.header}>
          STŘELCI
      </div>
      <div>
        <TableWithButton btnDest="/strelci" btnText="STŘELECKÁ TABULKA" children={items} />
      </div>
    </div>
  )
})

export default ShooterView;

import React, { useContext } from "react";
import {observer} from "mobx-react-lite";
import Header, { HeaderVariant } from "../RosterView/Header";
import RosterBlock, { FieldEnum } from "../RosterView/RosterBlock";
import styles from "./FullRosterView.module.css"
import dayjs from "dayjs";
import { MatchObject } from "../../../../../../state/MatchStore";
import { RootContext } from "../../../../../../App";

const RosterView = observer(() => {
  let allMatches: Map<string, MatchObject[]> = new Map()
  let postponedMatches: MatchObject[] = []
  const rootContext = useContext(RootContext);

  let keys: string[] = []

  for(var i in rootContext.matchStore.matches) {
    const match = rootContext.matchStore.matches[i];
    const datetime = dayjs(match.time).tz();

    if(match.postponed) {
      postponedMatches.push(match)
      continue
    }

    var date = datetime.format("DD. MM. YY")
    if(allMatches.has(date)) {
      allMatches.get(date)?.push(match);
    } else {
      allMatches.set(date, [match])
    }

    if(keys.includes(date) === false) {
      keys.push(date)
    }
  }

  postponedMatches.sort((a,b) => a.round < b.round ? -1 : 1)
  keys.sort((a, b) => dayjs(a, "DD. MM. YY").tz() < dayjs(b, "DD. MM. YY").tz() ? -1 : 1)
  
  let firstUpcomingMatchTimestamp: number = 0;
  const matches: JSX.Element[] = keys.map((date) => {
    const matchesForDay = allMatches.get(date)!.filter(m => !m.postponed);
    if(firstUpcomingMatchTimestamp === 0) {
      const datetime = dayjs(matchesForDay[0].time).tz()
      firstUpcomingMatchTimestamp = datetime.unix();
    }
    const old = false;

    return (
      <RosterBlock key={date} date={date} matches={matchesForDay} isOld={old} />
    )
  })

  return (
    <React.Fragment>
      <div className={styles.roster}>
        {postponedMatches.length > 0 && 
          <div style={{marginBottom: "30px"}}>
            <Header variant={HeaderVariant.Full} text1="ODLOŽENÉ ZÁPASY" />
            <RosterBlock key={"ODLOŽENO"} date={"ODLOŽENO"} matches={postponedMatches} isOld={false} postponed={true} />
          </div>
        }
        <div>
          <Header variant={HeaderVariant.Full} text1="ROZPIS VŠECH ZÁPASŮ" />
          {matches}
        </div>
      </div>
    </React.Fragment>
  )
});

export default RosterView;

import RosterItem from './RosterItem'
import styles from './RosterBlock.module.css'
import { MatchObject } from '../../../../../../state/MatchStore';
import dayjs from 'dayjs';
import { RootContext } from '../../../../../../App';
import { useContext } from 'react';
import { observer } from 'mobx-react-lite';

export enum FieldEnum {
  benzina,
  ziegel
}

interface RosterBlockProps {
  matches: MatchObject[];
  date: string;
  isOld: boolean;
  postponed?: boolean;
}

const RosterBlock = observer((props: RosterBlockProps) => {
  // const field = props.field == FieldEnum.benzina ? "ZA BENZINOU" : "ZIEGEL"
  const dateStyle = styles.date;
  const dateStyleOverride = props.isOld ? styles.old : ""

  const rootContext = useContext(RootContext);
  const teamStore = rootContext.teamStore

  let date = props.date
  if(props.postponed) {
    date = "ODLOŽENO"
  }

  const matchElements = props.matches.sort((a,b)=>dayjs(a.time) < dayjs(b.time) ? -1 : 1).map((match, idx) => {
    var datetime = dayjs(match.time).tz()
    var time = datetime.format("HH:mm")
    const weatherForecast = rootContext.weatherStore.getWeatherForDatetime(datetime.unix());
    const team1Name = teamStore.getTeamName(match.team1Id)
    const team2Name = teamStore.getTeamName(match.team2Id)

    if(props.postponed) {
      time = ""
    }

    return (
      <RosterItem 
        old={props.isOld} 
        key={idx} 
        matchId={match.uid} 
        round={match.round} 
        team1={team1Name} 
        team2={team2Name} 
        score1={match.score1} 
        score2={match.score2}
        weatherForecast={weatherForecast} 
        time={time} 
        played={match.played} 
        contumacy={match.contumacy}
        postponed={match.postponed}
      />
    )
  })

  return (
    <div className={styles.block}>
      <div className={`${dateStyle} ${dateStyleOverride}`}>
        <span>{date}</span>
        { !props.postponed && <span className={styles.small}> ZA BENZINKOU</span> }
      </div>
      <div className={styles.items}>
        {matchElements}
      </div>
    </div>
  )
})

// RosterBlock.defaultProps = {
//   field: FieldEnum.benzina,
//   played: true,
//   showWeather: false,
// };

export default RosterBlock;

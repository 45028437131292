import { match } from 'assert';
import React, { useState, useRef, useEffect, useMemo } from 'react';
import { WeatherForecast } from '../../../../../../state/WeatherStore';
import styles from './RosterItem.module.css'
import Scorers from './Scorers/Scorers';
import weather_0_clear from "../../../../../../images/weather/0_clear.svg"
import weather_1_mainly_clear from "../../../../../../images/weather/1_mainly_clear.svg"
import weather_2_partly_cloudy from "../../../../../../images/weather/2_partly_cloudy.svg"
import weather_3_overcast from "../../../../../../images/weather/3_overcast.svg"
import weather_45_48_fog from "../../../../../../images/weather/45_48_fog.svg"
import weather_51_53_55_drizzle from "../../../../../../images/weather/51_53_55_drizzle.svg"
import weather_56_57_freezing_drizzle from "../../../../../../images/weather/56_57_freezing_drizzle.svg"
import weather_61_light_rain from "../../../../../../images/weather/61_light_rain.svg"
import weather_63_moderate_rain from "../../../../../../images/weather/63_moderate_rain.svg"
import weather_65_heavy_rain from "../../../../../../images/weather/65_heavy_rain.svg"
import weather_66_67_freezing_rain from "../../../../../../images/weather/66_67_freezing_rain.svg"
import weather_71_light_snowfall from "../../../../../../images/weather/71_light_snowfall.svg"
import weather_73_moderate_snowfall from "../../../../../../images/weather/73_moderate_snowfall.svg"
import weather_75_85_86_heavy_snowfall from "../../../../../../images/weather/75_85_86_heavy_snowfall.svg"
import weather_77_hail from "../../../../../../images/weather/77_hail.svg"
import weather_80_81_82_showers from "../../../../../../images/weather/80_81_82_showers.svg"
import weather_95_96_99_thunderstorm from "../../../../../../images/weather/95_96_99_thunderstorm.svg"
import { ReactComponent as MatchArrow } from "../../../../../../images/matchArrow.svg"


interface RosterItemProps {
  matchId: string;
  team1: string;
  team2: string;
  score1: number;
  score2: number;
  weatherForecast: WeatherForecast;
  time: string;
  old: boolean;
  round: number;
  played: boolean;
  contumacy: boolean;
  postponed: boolean;
}

export default function RosterItem(props: RosterItemProps) {
  const weatherTable: Map<number, string> = new Map();
  weatherTable.set(0, weather_0_clear);
  weatherTable.set(1, weather_1_mainly_clear);
  weatherTable.set(2, weather_2_partly_cloudy);
  weatherTable.set(3, weather_3_overcast);
  weatherTable.set(45, weather_45_48_fog);
  weatherTable.set(48, weather_45_48_fog);
  weatherTable.set(51, weather_51_53_55_drizzle);
  weatherTable.set(53, weather_51_53_55_drizzle);
  weatherTable.set(55, weather_51_53_55_drizzle);
  weatherTable.set(56, weather_56_57_freezing_drizzle);
  weatherTable.set(57, weather_56_57_freezing_drizzle);
  weatherTable.set(61, weather_61_light_rain);
  weatherTable.set(63, weather_63_moderate_rain);
  weatherTable.set(65, weather_65_heavy_rain);
  weatherTable.set(66, weather_66_67_freezing_rain);
  weatherTable.set(67, weather_66_67_freezing_rain);
  weatherTable.set(71, weather_71_light_snowfall);
  weatherTable.set(73, weather_73_moderate_snowfall);
  weatherTable.set(75, weather_75_85_86_heavy_snowfall);
  weatherTable.set(85, weather_75_85_86_heavy_snowfall);
  weatherTable.set(86, weather_75_85_86_heavy_snowfall);
  weatherTable.set(77, weather_77_hail);
  weatherTable.set(80, weather_80_81_82_showers);
  weatherTable.set(81, weather_80_81_82_showers);
  weatherTable.set(82, weather_80_81_82_showers);
  weatherTable.set(95, weather_95_96_99_thunderstorm);
  weatherTable.set(96, weather_95_96_99_thunderstorm);
  weatherTable.set(99, weather_95_96_99_thunderstorm);

  const [active, setActive] = useState(false);
  // const [contentHeight, setContentHeight] = useState(0)
  const refContentRef: React.RefObject<HTMLDivElement> = useRef(null);

  let contentHeight = 0;
  const setContentHeight = (height: number) => {
    contentHeight = height
  }
  if (refContentRef.current) {
    setContentHeight(refContentRef.current.clientHeight);
  }

  const contentWrapStyle: React.CSSProperties = useMemo(() => (
    { height: active ? contentHeight : 0, transition: "height 0.5s", overflow: "hidden", boxSizing: "border-box", width: "100%" }
  ), [active, contentHeight]);
  const showScore: boolean = props.played
  const oldStyle: string = props.old ? styles.old : ""

  return (
    <div className={styles.item}>
      { props.time.length > 0 && 
        <div className={`${styles.time} ${oldStyle}`}>
          {props.time}
        </div>
      }
      {props.weatherForecast.code > -1 && !props.played &&
        <div className={styles.weather}>
          <img src={weatherTable.get(props.weatherForecast.code)} />
          {Math.round(props.weatherForecast.temp)}°C
        </div>
      }
      <button className={`${styles.vsContainer}`} onClick={() => { setActive(showScore && !active && !props.contumacy) }}>
        <div className={styles.vs}>
          <div className={styles.team1}>
            <span>{props.team1}</span>
          </div>
          <div className={styles.score}>
            <div className={styles.round}>{props.round}. KOLO</div>
            {showScore == true && <div className={styles.val}>{props.score1} : {props.score2}</div>}
            {showScore == false && <div className={styles.val}>-</div>}
            <div className={styles.arrowDiv}>
              { props.contumacy && "kontumace" }
              { !props.contumacy && <MatchArrow stroke="black" className={`${styles.arrow} ${showScore ? '' : styles.arrowHidden} ${active ? styles.active : ""}`} /> }
            </div>
          </div>
          <div className={styles.team2}>
            <span>{props.team2}</span>
          </div>
        </div>
        <div style={contentWrapStyle}>
          <Scorers wrapperRef={refContentRef} matchId={props.matchId} />
        </div>
      </button>
    </div>
  )
}

import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import FullRosterView from "./Content/FullRosterView/FullRosterView";
import styles from './MainPage.module.css'
import RosterView from "./Content/RosterView/RosterView";
import LeftColumnView from "./LeftColumn/LeftColumnView";
import FullTableView from "./Content/FullTableView/FullTableView";
import FullShooterView from "./Content/FullShooterView/FullShooterView";
import RightColumnView from "./RightColumn/RightColumnView";

export default function MainPage() {
  return (
    <React.Fragment>
      <div className={styles.leftCol}>
        <LeftColumnView />
      </div>
      <div className={styles.middleCol}>
        <Routes>
          <Route path="/tabulka" element={<FullTableView />} />
          <Route path="/strelci" element={<FullShooterView />} />
          <Route path="/liga/rozpis" element={<FullRosterView />} />
          <Route path="/zapasy" element={<RosterView />} />
          <Route path="*" element={<RosterView />} />
          {/* <Route path="*" element={<Navigate replace to="/ukonceni" />} /> */}
        </Routes>
      </div>
      <div className={styles.rightCol}>
        <RightColumnView />
      </div>
    </React.Fragment>
  )
}

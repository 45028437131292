import { observer } from "mobx-react-lite"
import React, { useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { RootContext } from "../../App"
import styles from "./Login.module.css"

const LoginPage = observer(() => {
  const context = useContext(RootContext)
  const transport = context.transport;
  const navigate = useNavigate();

  const logged = context.authStore.isAuthenticated;
  useEffect(() => {
    if(logged) {
      // console.log("login redir: logged")
      navigate("/admin")
    } 
  }, [logged])

  function login() {
    setShowBadEmail(false);
    setShowBadPassword(false);
    context.authStore.login(
      email,
      password,
      () => {setShowBadEmail(true)},
      () => {setShowBadPassword(true)}
    )
  }

  function handleSubmit(event: React.SyntheticEvent) {
    login()
    event.preventDefault();
  }

  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [showBadEmail, setShowBadEmail] = useState(false)
  const [showBadPassword, setShowBadPassword] = useState(false)

  return (
    <div className={styles.page}>
      <div className={styles.header}>
        Přihlášení
      </div>
        <form className={styles.loginContainer} onSubmit={handleSubmit}>
          <input className={styles.input} placeholder="Email" onChange={e => setEmail(e.target.value)} />
          <input type="password" className={styles.input} placeholder="Heslo" onChange={e => setPassword(e.target.value)} />
          <input type="submit" className={styles.button} value="Přihlásit" />
        </form>
        {(showBadEmail || showBadPassword) &&
        <div className={styles.loginError}>
          {showBadEmail && 
            <div>
              Špatný Email
            </div>}
          {showBadPassword && 
            <div>
              Špatné heslo
            </div>}
        </div>
        }
        <div className={styles.backToWeb} onClick={() => {navigate("/")}}>
          Zpět na Web
        </div>
    </div>
  )
});

export default LoginPage;

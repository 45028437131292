import { useContext } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { RootContext } from "../../../../App";
import { BackofficeContext, Header } from "../../BackofficePage";
import styles from "../MatchesPage/MatchesPage.module.css"
import TeamEditView from "./TeamEditView";
import TeamsTable from "./TeamsTable";

const TeamsPage = () => {
  const ctx = useContext(RootContext)

  return (
    <div className={styles.page}>
      <Routes>
        <Route path="edit/:teamId/*" element={<TeamEditView />} />
        <Route path="*" element={<TeamsTable teamStore={ctx.teamStore}/>} />
      </Routes>
    </div>
  )
}

export default TeamsPage;
import { useCallback, useEffect, useMemo, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { IconHamburger } from "../Backoffice/BackofficePage";
import styles from "./Frontend.module.css"
import AboutPage from "./Pages/AboutPage/AboutPage";
import DiscussPage from "./Pages/DiscussPage/DiscussPage";
import WelcomeView from "./Pages/MainPage/Content/WelcomeView/WelcomeView";
import MainPage from "./Pages/MainPage/MainPage";
import RulesPage from "./Pages/RulesPage/RulesPage";
import TeamsPage from "./Pages/TeamsPage/TeamsPage";

export default function Frontend() {
  // const [header, setHeader] = useState("Zápasy")
  const [header, setHeader] = useState("Ukončení sezóny")

  return (
    <>
      <div className={styles.frontend}>
        <div className={styles.mobileNav}>
          <div className={styles.hamburger}><BurgerMenu setHeader={setHeader} /></div>
          <div className={styles.header}>{header}</div>
        </div>
        <div className={styles.main}>
          <Routes>
            {/* <Route path="/diskuze" element={<DiscussPage />} /> */}
            <Route path="/liga/pravidla" element={<RulesPage />} />
            <Route path="/liga/tymy" element={<TeamsPage />} />
            <Route path="/liga/onas" element={<AboutPage />} />
            {/* <Route path="/ukonceni" element={<WelcomeView />} /> */}
            <Route path="/diskuze" element={<DiscussPage />} />
            <Route path="*" element={<MainPage />} />
          </Routes>
        </div> 
      </div>
    </>
  )
}

interface BurgerMenuProps {
  setHeader(text: string): void;
}


function BurgerMenu(props: BurgerMenuProps) {
  const [isOpen, setIsOpen] = useState(false)
  const navigate = useNavigate()

  const handleItemClick = (header: string, href: string, e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    props.setHeader(header)
    navigate(href);
    setIsOpen(false);
    e.preventDefault();
  }

  return (
    <>
      <div onClick={() => {setIsOpen(true)}}>
        <IconHamburger color="white" />
      </div>
      {isOpen && <div className={styles.overlay} onClick={(e) => {e.preventDefault(); setIsOpen(false)}} />}
      <div className={`${styles.sideMenu} ${isOpen ? styles.sideMenuOpen : styles.sideMenuClosed}`} onClick={(e) => {e.preventDefault()}}>
        {/* <div className={styles.sideMenuItem} onClick={(e) => {handleItemClick("Ukončení sezóny", "ukonceni", e)}}>Ukončení sezóny</div> */}
        <div className={styles.sideMenuItem} onClick={(e) => {handleItemClick("Zápasy", "zapasy", e)}}>Zápasy</div>
        <div className={styles.sideMenuItem} onClick={(e) => {handleItemClick("Tabulka", "tabulka", e)}}>Tabulka</div>
        <div className={styles.sideMenuItem} onClick={(e) => {handleItemClick("Střelci", "strelci", e)}}>Střelci</div>
        <div className={styles.sideMenuItem} onClick={(e) => {handleItemClick("Týmy", "liga/tymy", e)}}>Týmy</div> 
        <div className={styles.sideMenuItem} onClick={(e) => {handleItemClick("Rozpis", "liga/rozpis", e)}}>Rozpis</div> 
        <div className={styles.sideMenuItem} onClick={(e) => {handleItemClick("Pravidla", "liga/pravidla", e)}}>Pravidla</div> 
        <div className={styles.sideMenuItem} onClick={(e) => {handleItemClick("Diskuze", "diskuze", e)}}>Diskuze</div> 
        
        {/* <div className={styles.sideMenuItem} onClick={(e) => {handleItemClick("", "/admin", e)}}>Admin</div> */}
      </div>
    </>
  )
}

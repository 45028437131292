import { MatchObject } from "./MatchStore"
import { WeatherForecastData } from "./WeatherStore";

import { TeamInfoDTO } from "./TeamStore";

import ky from 'ky'
import { rootStore, yearStore } from "./RootStore";

const hooks = {
  beforeRequest: [
    (options: any) => {
      const token = localStorage.getItem("jwtToken") || "";
      if (token !== "" && options.method != "GET") {
        options.headers.set('Authorization', `Bearer ${token}`);
      }
    },
  ],
  afterResponse: [],
}

const api = ky.create({ prefixUrl: 'https://api.hamaro.cz/api', hooks: hooks });
// const api = ky.create({ prefixUrl: 'http://localhost:8080/api', hooks: hooks });
// const api = ky.create({ prefixUrl: 'http://10.0.1.50:8080/api', hooks: hooks });

export interface PlayerDTO {
  uid: string;
  id: number;
  name: string;
  teamId: number;
  rowState: string;
}

export class TeamScoreDTO {
  team_id: number;

  matches: number = 0;
  won: number = 0;
  lost: number = 0;
  draws: number = 0;
  goals_given: number = 0;
  goals_taken: number = 0;
  points: number = 0;

  constructor(team_id: number) {
    this.team_id = team_id;
  }
}

interface WeatherWrapperDTO {
  data: string;
}

export class MatchObjectDTO {
  round: number = 0;
  time: number = 0;
  field: string = "";
  referee: string = "";
  team1_id: number = -1;
  team2_id: number = -1;
  team1_scorers: number[] = []
  team2_scorers: number[] = []
}

export interface DiscussionItemDTO {
  id: string;
  parentId: string|null;
  timestamp: number;
  name: string;
  message: string;
}

 class TransportLayer {

  async fetchTeams(): Promise<TeamInfoDTO[]> {
    const data = await api.get('teams').json<TeamInfoDTO[]>();
    return data;
  }

  async fetchMatchYears(): Promise<number[]> {
    return await api.get(`matches/years`).json<number[]>();
  }

  async fetchMatches(): Promise<MatchObject[]> {
    const data = await api.get(`matches?year=${yearStore.year}`).json<MatchObject[]>();
    return data;
  }

  async fetchTemperatures(): Promise<WeatherForecastData> {
    const data = await api.get('weather').json<any>();
    // console.log(`Parsing ${data}`)
    const hourly = data.hourly;
    
    const times: string[] = hourly.time;
    const temps: number[] = hourly.temperature_2m;
    const codes: number[] = hourly.weathercode;

    const forecast = new WeatherForecastData(times, temps, codes);
    return forecast;
  }

  async fetchPlayers(itemsPerPage: number = 100): Promise<PlayerDTO[]> {
    const players = await api.get('players').json<PlayerDTO[]>();
    return players
  }

  async updatePlayer(player: PlayerDTO) {
    await api.post("players", { json: player }).json()
  }

  async fetchDiscussion(itemsPerPage: number = 100): Promise<DiscussionItemDTO[]> {
    const discussions = await api.get('discussion').json<DiscussionItemDTO[]>();
    // console.log(discussions)
    return discussions;
  }

  async updateMatch(match: MatchObject) {
    await api.post("matches", { json: match }).json()
  }

  async postComment(comment: DiscussionItemDTO) {
    await api.post("discussion", { json: comment }).json()
  }

  // async login(email: string, password: string, badEmailCallback: TransportLayer.VoidCallback, badPasswordCallback: TransportLayer.VoidCallback): Promise<Models.Session> {
  //   let session: Models.Session;
    
  //   try {
  //     session = await account.createEmailSession(email, password)
  //     console.log(`T: ${JSON.stringify(session)}`)
  //   } catch (ex: any) {
  //     if(ex.code == 400) { badEmailCallback() }
  //     if(ex.code == 401) { badPasswordCallback() }
  //   }

  //   return session!!;
  // }

  async login(email: string, password: string) {
    interface JwtResponse { 
      username: string,
      access_token: string,
      token_type: string,
      expires_in: string
    }
    interface AuthPayload {
      username: string,
      password: string
    }

    const payload: AuthPayload = {
      username: email,
      password: password
    }

    const jwtResponse = await api.post("login", { json: payload }).json<JwtResponse>()
    return jwtResponse.access_token;
  }
}

namespace TransportLayer {
  export type VoidCallback = () => void
}

export default TransportLayer

import { EmailOutlined, Phone } from "@mui/icons-material"
import styles from "./ContactView.module.css"

const ContactView = () => {
  return (
    <div className={styles.view}>
      <div className={styles.contact}>
        <div className={styles.contactHeader}>
          KONTAKTY - VEDENÍ LIGY
        </div>
        <div className={styles.contactContent}>
          <div className={styles.contactItemLeaders}>
            <div className={styles.contactItemName}>
              Marek Urbiš
            </div>
            <div className={styles.contactItemPhone}>
              <div style={{display:"inline",position:"relative"}}>
                <Phone style={{position:"absolute", left: "-30px", top:"3px",verticalAlign:"middle"}} fontSize="small" /> 
                732 874 212
              </div>
            </div>
            <div className={styles.contactItemEmail}>
              <div style={{display:"inline",position:"relative"}}>
                <EmailOutlined style={{position:"absolute", left: "-30px", top:"3px",verticalAlign:"middle"}} fontSize="small"/>
                <a href="mailto:marek.urbis@seznam.cz">marek.urbis@seznam.cz</a>
              </div>
            </div>
          </div>
          <div className={styles.contactItemWebmaster}>
            <span>WEBMASTER</span><br />
          </div>
          <div className={styles.contactItemWebmasterContact}>
            <div className={styles.contactItemName}>
              Marek Hanzlik
            </div>
            <div className={styles.contactItemPhone}>
              <div style={{display:"inline",position:"relative"}}>
                <Phone style={{position:"absolute", left: "-30px", top:"3px",verticalAlign:"middle"}} fontSize="small" /> 
                777 021 992
              </div>
            </div>
            <div className={styles.contactItemEmail}>
              <div style={{display:"inline",position:"relative"}}>
                <EmailOutlined style={{position:"absolute", left: "-30px", top:"3px",verticalAlign:"middle"}} fontSize="small"/>
                <a href="mailto:horkyze@gmail.com">horkyze@gmail.com</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContactView;

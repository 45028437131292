import styles from "./MatchesPage.module.css"
import "./Datepicker.css"

import { registerLocale, setDefaultLocale } from  "react-datepicker";
import { cs } from 'date-fns/locale';
import AddMatchBox from "./AddMatchBox";
import MatchesTable from "./MatchesTable";
import { observer } from "mobx-react-lite" 
import { MatchObject, MatchStore } from "../../../../state/MatchStore";
import { RootContext } from "../../../../App";
import { useContext, useEffect, useState } from "react";
import { Header, BackofficeContext } from "../../BackofficePage";
import { Route, Routes, ScrollRestoration, useLocation, useNavigate, useNavigationType, useParams } from "react-router-dom";
import MatchEditView from "./MatchEditView";
import { ulid } from "ulid";


registerLocale('cs', cs)

const MatchesPage = observer(() => {
  const bkofficeCtx = useContext(BackofficeContext)
  const rootContext = useContext(RootContext)

  const navigate = useNavigate();

  const onMatchClicked = (matchId: string) => {
    navigate(`edit/${matchId}`)
  }

  const onMatchCreateClicked = () => {
    const id = ulid()
    navigate(`create/${id}`)
  }

  return (
    <div className={styles.page}>
      <Routes>
        <Route path="edit/:matchId" element={<MatchEditView />} />
        <Route path="create/:matchId" element={<AddMatchBox match={undefined} onSubmit={() => {}} />} />
        {/* <Route path="*" element={<MatchesTable matchStore={rootContext.matchStore} teamStore={rootContext.teamStore} onMatchClicked={onMatchClicked}/>} /> */}
      </Routes>
      <MatchesTable 
        matchStore={rootContext.matchStore}
        teamStore={rootContext.teamStore}
        onMatchClicked={onMatchClicked}
        onMatchCreateClicked={onMatchCreateClicked}
      />
    </div> 
  )
});

export default MatchesPage

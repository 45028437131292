import React, { useEffect, useMemo, useRef, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import styles from './NavItem.module.css'
import useDocumentClickHandler from './OutsideAlertgerHook';
import matchArrow from '../images/matchArrow.svg'


export interface NavItemProps {
  text: string;
  href: string;
  subItems?: NavItemProps[]
  classNames?: string[]
  hideArrow?: boolean
  onClick?(): void
}
interface DropdownProps {
  open: boolean;
  subItems: NavItemProps[];
  onClick?: any;
}

function Dropdown(props: DropdownProps) {
  const navigate = useNavigate()

  const onClick = (href: string) => {
    navigate(href)
    if(props.onClick != undefined)
      props.onClick()
  }

  return (
   <ul className={`${styles.dropdown}`}>
    {props.subItems.map((item, index) => (
     <li key={index} className={styles.menuItems} onClick={() => { item.onClick ? item.onClick() : onClick(item.href); props.onClick() }}>
      {item.text}
     </li>
    ))}
   </ul>
  );
 };

export default function NavItem(props: NavItemProps) {
  const wrapperRef = useRef(null);
  useDocumentClickHandler(wrapperRef, () => {
      setOpen(false);
  });

  const hideArrow = props.hideArrow || false
  const arrow = (props.subItems && !hideArrow) ? <img className={styles.arrowImg} src={matchArrow} /> : null;
  const subItems = props.subItems ? props.subItems! : undefined
  
  const [open, setOpen] = useState(false)

  const [contentHeight, setContentHeight] = useState(0)
  const refContentRef: React.RefObject<HTMLDivElement> = useRef(null);
  useEffect(() => {
    if (refContentRef.current) {
        setContentHeight(refContentRef.current.clientHeight);
      }
    }, []);
  const contentWrapStyle: React.CSSProperties = useMemo(() => (
    { height: open ? contentHeight : 0, overflow: open ? "" : "hidden" }
  ), [open, contentHeight]);


  return (
    <>
      {props.subItems ? (
        <div ref={wrapperRef} className={`${styles.navItem} ${props.classNames?.join(' ')}`}>
          <button className={styles.navItem} type="button" aria-haspopup="menu" onClick={() => setOpen((prev) => !prev)}>{props.text}{arrow}</button>
          
          <div style={{ ...contentWrapStyle, position: 'absolute', top: 60, right: -5, zIndex: 11000}}>
            <div ref={refContentRef}>

              {
                subItems !== undefined &&
                  <Dropdown subItems={subItems} open={open} onClick={() => setOpen((prev) => !prev)} />
            
              }
            </div>
          </div>
          
        </div>
      ) : (
        <NavLink className={styles.navItem} to={props.href}>{props.text}{arrow}</NavLink>
      )}
    </>
  )
}

import { Header } from "../../BackofficePage";
import styles from "./TeamsTable.module.css"
import { TeamStore } from "../../../../state/TeamStore";
import { useNavigate } from "react-router-dom";

export interface TeamsTableProps {
  teamStore: TeamStore
}

const TeamsTable = (props: TeamsTableProps) => {
  const navigate = useNavigate()
  

  return (
    <div className={styles.matchesTableContainer}>
      <Header title="Tymy" />
      <div className={styles.tableMain}>
        <div className={`${styles.tableHeader} ${styles.header}`}>
          <div>Nazev tymu</div>
        </div>
        <div className={styles.table}>
          {props.teamStore.teams.slice().reverse().map(team => (
            <div key={team.id} className={`${styles.tableItem} ${team.id % 2 === 0 ? styles.even : ''}`} onClick={() => { navigate(`edit/${team.id}`) }}>
              {team.name}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default TeamsTable;


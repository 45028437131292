import { action, observable } from "mobx";
import { observer } from "mobx-react-lite";
import { useContext, useEffect, useMemo, useState } from "react"
import { Route, Routes, useLocation, useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import { ulid } from "ulid";
import { RootContext } from "../../../../App";
import CustomCheckbox from "../../../../Components/CustomCheckbox";
import { PlayerDTO } from "../../../../state/TransportLayer";
import czSort from "../../../../utils/czSort";
import { BackofficeContext, Header, IconBack, IconSave } from "../../BackofficePage"
import styles from "./TeamEditView.module.css"

const teamEditViewStore = observable({
  editedPlayer: {}
})

const updatePlayerNameAction = action((state: any, data: string) => {
  state.editedPlayer.name = data
})

const TeamEditView = observer(() => {
  const backofficeCtx = useContext(BackofficeContext)
  const context = useContext(RootContext);
  
  const { teamId } = useParams()
  const location = useLocation()
  const navigate = useNavigate()
  
  const teamStore = context.teamStore;
  const playerStore = context.playerStore;

  const nTeamId = parseInt(teamId!!) || -1
  const team = teamStore.getTeam(nTeamId)
  const players = playerStore.getPlayersInTeam(nTeamId)

  players.sort((a, b) => czSort(a.name, b.name))
  
  useEffect(() => {
    backofficeCtx.hideBottomMenu = true
    return () => {
      backofficeCtx.hideBottomMenu = false
    }
  }, [])
  
  
  const save = () => {
    const p: PlayerDTO = teamEditViewStore.editedPlayer as PlayerDTO;
    console.log("Saving")
    console.log(JSON.stringify(p))
    playerStore.updatePlayer(p);
  }
  
  if (team === undefined || players === undefined) return (<div>Loading</div>)

  const editing = location.pathname.includes("hrac")

  return (
    <div className={styles.main}>
      <Header title={team!!.name} leftChild={<IconBack />} rightChild={editing ? <IconSave /> : undefined} onLeftChildClick={() => {navigate(-1)}} onRightChildClick={save} />
      <Routes>
        <Route path="hrac/:playerId" element={<PlayerEditView />} />
        <Route path="*" element={<TeamSettings />} />
      </Routes>
      <TeamEditList hidden={editing} players={players} />
    </div>      
  )
})

export default TeamEditView

const TeamSettings = observer(() => {
  const [active, setActive] = useState(true)

  return (
    <div className={styles.teamSettingsView}>
      <CustomCheckbox checked={active} label="Aktivni" onChange={() => { setActive(!active) }} />
    </div>
  )
})

const PlayerEditView = observer(() => {
  const rootContext = useContext(RootContext)
  const { teamId, playerId } = useParams()

  const options = rootContext.teamStore.teams.map(team => {
    return {
      value: team.id, label: team.name
    }
  })
  const selectStyles = {
    container: (base: any) => ({
      ...base,
      fontSize: "24px"
    })
  };
  
  console.log("again");
  const newPlayer: PlayerDTO = useMemo(
    () => {
      console.log(`New ID: ${rootContext.playerStore.getNewId()}`)
      return { uid: ulid(), id: rootContext.playerStore.getNewId(), name: "", teamId: parseInt(teamId!!), rowState: "" }
    }, [rootContext.playerStore.players]
  )

  const player = playerId === "novy" ? newPlayer : rootContext.playerStore.getPlayerById(parseInt(playerId!!))!!
  teamEditViewStore.editedPlayer = player;
  
  const [playerName, setPlayerName] = useState(player?.name)
  const [playerTeamId, setPlayerTeamId] = useState(player?.teamId)

  const idx = options.findIndex(item => { return item.value === playerTeamId }) || -1
  
  useEffect(() => {
    setPlayerName(player?.name || "")
    setPlayerTeamId(player?.teamId || -1)
    teamEditViewStore.editedPlayer = player;
  }, [player])

  useEffect(() => {
    // (teamEditViewStore.editedPlayer as PlayerDTO).name = playerName
    updatePlayerNameAction(teamEditViewStore, playerName)
  }, [playerName])

  if(player === undefined) return (<div>Loading</div>)
  return (
    <div className={styles.editPlayerContainer}>
      <div className={styles.editInput}>
        <input type="text" onChange={v => { setPlayerName(v.target.value) }} value={playerName} />
        <div />
        <Select isSearchable={false} styles={selectStyles} options={options} placeholder="Tym A" value={options[idx]} onChange={(opt) => { setPlayerTeamId(opt!!.value) }} />
      </div>
    </div>
  )
})

interface TeamEditListProps {
  players: PlayerDTO[]
  hidden: boolean
}

const TeamEditList = observer((props: TeamEditListProps) => {
  const navigate = useNavigate()
  
  return (
    <div className={`${styles.players} ${props.hidden ? styles.hide : ''}`}>
      <div className={`${styles.newPlayer} ${styles.player}`} onClick={() => { navigate("hrac/novy")}}>
        <div>
          Přidat nového hráče
        </div>
        <div className={styles.buttons}>
        </div>
      </div>
      {props.players.map(p => {
        return (
          <div key={p.id} className={styles.player} onClick={() => { navigate(`hrac/${p.id}`) }}>
            <div>
              {p.name}
            </div>
            <div className={styles.buttons}>
            </div>
          </div>
        )
      })}
    </div>
  )
})

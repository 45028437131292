import { CountdownCircleTimer } from "react-countdown-circle-timer";
import {observer} from "mobx-react-lite";
import styles from "./Timer.module.css";
import { useContext, useEffect, useState } from "react";
import { RootContext } from "../../../../../../App";
import dayjs from "dayjs";
import { useMediaQuery } from "react-responsive";

const minuteSeconds = 60;
const hourSeconds = 3600;
const daySeconds = 86400;

interface TimerProps {
  nextMatchTimestamp: number;
}

const renderTime = (dimension: string, time: number) => {
  return (
    <div className={styles.time_wrapper}>
      <div className={styles.time}>{time}</div>
      <div>{dimension}</div>
    </div>
  );
};

const getTimeMinutes = (time: number) => ((time % hourSeconds) / minuteSeconds) | 0;
const getTimeHours = (time: number) => ((time % daySeconds) / hourSeconds) | 0;
const getTimeDays = (time: number) => (time / daySeconds) | 0;

const Timer = observer((props: TimerProps) => {
  // const handleMobileChange = (matches: boolean) => {
  //   console.log("Changes to " + matches)
  //   if (matches) setTimerSize(45);
  //   else setTimerSize(65);
  // }

  const isMobile = useMediaQuery({ query: '(max-width: 1224px)' })
  const timerSize = isMobile ? 45 : 65
  const strokeWidth = isMobile ? 5 : 6

  if(props.nextMatchTimestamp === 0) {
    return (<div></div>)
  }
  
  const nextMatchTime: number = props.nextMatchTimestamp;
  const now = dayjs().unix() // use UNIX timestamp in seconds
  const endTime = nextMatchTime; // use UNIX timestamp in seconds

  const remainingTime = endTime - now;
  const days = Math.ceil(remainingTime / daySeconds);
  const daysDuration = days * daySeconds;

  return (
    <div className={styles.timer}>
      <div className={styles.circle}>
        <CountdownCircleTimer
          isPlaying={true}
          size={timerSize}
          strokeWidth={strokeWidth}
          colors="#163C46"
          trailColor="#9DCAD6"
          duration={daysDuration}
          initialRemainingTime={remainingTime}
        >
          {({ elapsedTime, color }) => (
            <span style={{ color }}>
              {renderTime("D", getTimeDays(daysDuration - elapsedTime))}
            </span>
          )}
        </CountdownCircleTimer>
      </div>
      <p>:</p>
      <div className={styles.circle}>
        <CountdownCircleTimer
          isPlaying={true}
          size={timerSize}
          strokeWidth={strokeWidth}
          colors="#163C46"
          trailColor="#9DCAD6"
          duration={daySeconds}
          initialRemainingTime={remainingTime % daySeconds}
          onComplete={(totalElapsedTime) => ({
            shouldRepeat: remainingTime - totalElapsedTime > hourSeconds
          })}
        >
          {({ elapsedTime, color }) => (
            <span style={{ color }}>
              {renderTime("H", getTimeHours(daySeconds - elapsedTime))}
            </span>
          )}
        </CountdownCircleTimer>
      </div>
      <p>:</p>
      <div className={styles.circle}>
        <CountdownCircleTimer
          isPlaying={true}
          size={timerSize}
          strokeWidth={strokeWidth}
          colors="#163C46"
          trailColor="#9DCAD6"
          duration={hourSeconds}
          initialRemainingTime={remainingTime % hourSeconds}
          onComplete={(totalElapsedTime) => ({
            shouldRepeat: remainingTime - totalElapsedTime > minuteSeconds
          })}
        >
          {({ elapsedTime, color }) => (
            <span style={{ color }}>
              {renderTime("M", getTimeMinutes(hourSeconds - elapsedTime))}
            </span>
          )}
        </CountdownCircleTimer>
      </div>
      <p className={styles.smaller_text}>DO ZÁPASU</p>
    </div>
  );
})

export default Timer;

import styles from './TableItem.module.css'

export interface TableItemProps {
  position: string;
  text: string;
  logoSrc: string;
  value: string;
}

export default function Item(props: TableItemProps) {
  return (
    <div className={styles.item}>
      <div className={styles.position}>
        {props.position}
      </div>
      <div className={styles.positionMarker}>
          |
      </div>
      <div className={styles.name}>
        {props.text}
      </div>
      <div className={styles.logo}>
        <img src={props.logoSrc} />
      </div>
      <div className={styles.score}>
        {props.value}
      </div>
    </div>
  )
}

export interface SimpleItemProps {
  text: string
}

export function SimpleItem(props: SimpleItemProps) {
  return (
    <div className={styles.item}>
      <div>
        {props.text}
      </div>
    </div>
  )
}

import React, { useContext, useState } from "react";
import { RootContext } from "../../../../../App";
import ContactView from "./ContactView";
import TableView from "./TableView";

export default function LeftColumnView() {
  const rootContext = useContext(RootContext)

  return (
    <React.Fragment>
      <TableView  />
      <ContactView />
    </React.Fragment>
  )
}

